import React, { useEffect } from "react";
import useNotifications from "../../../hooks/useNotifications";
import { Link } from "react-router-dom";

const View404 = () => {
  const [setNotification] = useNotifications();

  useEffect(() => {
    setNotification({ title: "Error", text: "Bad url!", type: "error" });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center pt-3">
          <h1 className="text-grey-dark">Error 404 - Not found</h1>
          <p>
            <Link to="/" className="btn btn-corporate">
              Go Home
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default View404;
