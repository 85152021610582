import React from "react";
import productConfigurator from "../../../../../services/ProductConfigurator";
import "./product-info-styles.scss";

const ProductInfo = ({ lastConfigUpdate, myDesign }) => {
  if (!lastConfigUpdate) {
    return null;
  }
  return (
    <div className="product-info-container">
      <h1>{productConfigurator.configuration.model.name}</h1>
      {myDesign && (
        <div className="text-red font-bold mb-3">{myDesign.title}</div>
      )}
      <p className="description">
        {productConfigurator.configuration.model.description}
      </p>
      {productConfigurator.configuration.model.note && (
        <p>{productConfigurator.configuration.model.note}</p>
      )}
    </div>
  );
};

export default ProductInfo;
