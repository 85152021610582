import React, { useEffect, useMemo } from "react";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import useProduct from "../../../hooks/useProduct";
import Spinner from "../../blocks/Spinner";
// import useActivityTrack from "../../hooks/useActivityTrack";
import { Link } from "react-router-dom";
import ProductsListTemplate from "../../templates/ProductsListTemplate";
import ProductCard from "../../blocks/ProductCard";

const SearchResults = (props) => {
  // const [
  //   action,
  //   errorRegistering,
  //   registering,
  //   registerAction
  // ] = useActivityTrack();
  const [breadcrumbData, setBreadcrumbData] = useBreadcrumb();
  const [products, error, fetching, getProducts] = useProduct({});

  useEffect(() => {
    setBreadcrumbData({
      levels: [
        {
          name: props.match.params.searchText,
          link: `/search-result/${props.match.params.searchText}`,
        },
      ],
      isLoading: false,
    });
    getProducts();
    // registerAction("SEARCH_TERM", {
    //   searchedTerm: props.match.params.searchText
    // });
  }, [props.match.params.searchText]);

  const searchedProductsResultKeys = useMemo(() => {
    const productKeys = Object.keys(products);
    if (productKeys.length === 0) {
      return [];
    }
    return productKeys.filter((key) => {
      return (
        (products[key].model.name
          .toLowerCase()
          .search(props.match.params.searchText.toLowerCase()) >= 0 ||
          products[key].model.description
            .toLowerCase()
            .search(props.match.params.searchText.toLowerCase()) >= 0) &&
        products[key].model.collections.length > 0
      );
    });
  }, [products]);

  return (
    <ProductsListTemplate>
      {fetching && <Spinner />}
      {!fetching &&
        searchedProductsResultKeys.length > 0 &&
        searchedProductsResultKeys.map((key, i) => {
          return (
            <ProductCard
              key={key}
              to={`/configurator/${key}`}
              snapUrl={products[key].model.snap}
              productName={products[key].model.name}
            />
          );
        })}
      {!fetching && searchedProductsResultKeys.length === 0 && (
        <h1 className="text-red">Sorry, no results found</h1>
      )}
    </ProductsListTemplate>
  );
};

export default SearchResults;
