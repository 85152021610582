import React from "react";
import useContact from "../../../hooks/useContact";
import Spinner from "../Spinner";
import useForm from "react-hook-form";
import countries from "../../../services/CountriesList";

const ContactForm = ({ product }) => {
  const { register, handleSubmit, errors } = useForm();
  const { performContact, data: contactDone, error, fetching } = useContact();

  const onSubmitNeedAdvice = (data) => {
    performContact({
      ...data,
      productId: product ? product.productId : null,
    });
  };

  return (
    <>
      {!fetching && (
        <>
          <form onSubmit={handleSubmit(onSubmitNeedAdvice)} className="form">
            <input
              type="text"
              name="subject"
              placeholder="Project"
              className="form-control mb-2 with-border-down bg-transparent"
              ref={register({ required: true })}
            />
            {errors.subject && (
              <span className="text-red">Project is required</span>
            )}
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="form-control mb-2 with-border-down bg-transparent"
              ref={register({ required: true })}
            />
            {errors.name && <span className="text-red">Name is required</span>}
            <input
              type="text"
              name="email"
              placeholder="Email"
              className="form-control mb-2 with-border-down bg-transparent"
              ref={register({ required: true })}
            />
            {errors.email && (
              <span className="text-red">Email is required</span>
            )}
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              className="form-control mb-2 with-border-down bg-transparent"
              ref={register({ required: true })}
            />
            {errors.phone && (
              <span className="text-red">Phone is required</span>
            )}
            {product && (
              <div className="mb-2">
                <span className="text-black">Product:</span>{" "}
                <span className="text-red font-bold">{product.name}</span>
              </div>
            )}
            {!product && (
              <>
                <select
                  name="country"
                  id=""
                  ref={register}
                  defaultValue=""
                  className="form-control mb-2 with-border-down bg-transparent"
                >
                  <option value="" disabled={true}>
                    Country
                  </option>
                  {countries.map((country) => {
                    return (
                      <option key={country.code} value={country.name}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="text"
                  name="city"
                  ref={register}
                  placeholder="City"
                  className="form-control mb-2 with-border-down bg-transparent"
                />
              </>
            )}
            <textarea
              name="message"
              className="form-control mb-2 with-border-down bg-transparent"
              placeholder="Write comments"
              id=""
              cols="30"
              rows="8"
              ref={register({ required: true })}
            ></textarea>
            {errors.message && (
              <span className="text-red">Comments are required</span>
            )}
            <div className="text-center">
              <button type="submit" className="btn btn-corporate btn-filled">
                Send
              </button>
            </div>
          </form>
          {contactDone && (
            <div className="text-success text-center py-3">
              Message sent correctly
            </div>
          )}
        </>
      )}
      {fetching && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ContactForm;
