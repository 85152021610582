import { EP } from "../services/EP";
import useModules from "./useModules";
import useHttp from "./useHttp";
import useUser from "./useUser";

const useActivityTrack = () => {
  const [dataUser] = useUser();
  const [responseData, error, fetching, { post }] = useHttp();
  const isActivityTrackModuleActive = useModules("ACTIVITY_TRACK");

  const trackFields = {
    userId: dataUser.id, //ID
    action: undefined, //string
    tstamp: undefined, //timestamp
    segment: undefined, //string
    product: undefined, //ID
    configurationKey: undefined, //string
    configurationValue: undefined, //string
    view: undefined, //string
    downloadPdf: false, //boolean
    myDesignId: undefined, //ID
  };

  const registerAction = async (action, dataAction) => {
    if (!dataUser.token) {
      return null;
    }
    if (!isActivityTrackModuleActive) {
      return null;
    }
    const actionValue = {
      ...trackFields,
      ...dataAction,
      action: action,
      tstamp: Date.now(),
    };
    return await post(`${EP}track/`, actionValue);
  };
  return [responseData, error, fetching, registerAction];
};

export default useActivityTrack;
