import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { Link } from "react-router-dom";
import "./breadcrumb-style.scss";

const Breadcrumb = () => {
  const history = useHistory();
  const location = useLocation();
  const [breadrcumbData] = useBreadcrumb();

  return (
    <div className="breadcrumb-container d-flex">
      {location.pathname !== "/" && (
        <button onClick={history.goBack} className="back-btn">
          <img src="imgs/breadcrumb-back-btn.svg" alt="" />
        </button>
      )}
      {!breadrcumbData.isLoading && (
        <ul className="breadcrumb-list">
          {breadrcumbData.levels.map((level, i) => {
            return (
              <li key={i}>
                <Link to={level.link}>{level.name}</Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Breadcrumb;
