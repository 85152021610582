import React, { createContext } from "react";

const ModulesContext = createContext();

const modules = {
  CONFIGURATOR: {
    AR_MODE: false,
    CREATE_PDF: true,
    TAKE_PHOTO: true,
    UNDO_REDO: true,
    FULL_SCREEN: true,
    CONFIGURATION_MENU: true,
    RELATED_PRODUCTS: true,
  },
  MY_DESINGS: true,
  SEARCH_PRODUCT: true,
  ACTIVITY_TRACK: true,
  HELP_VIEW: false,
};

const resolve = (path, obj) => {
  return path.split(".").reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj || modules);
};

const checkModule = (_module) => {
  return resolve(_module, modules);
};

const ModulesContextProvider = ({ children }) => {
  return (
    <ModulesContext.Provider value={checkModule}>
      {children}
    </ModulesContext.Provider>
  );
};

export { ModulesContext, ModulesContextProvider };
