import React, { useState } from "react";
import "./render-actions-menu-styles.scss";
import useModules from "../../../../../hooks/useModules";
import productConfigurator from "../../../../../services/ProductConfigurator";
import undoStack from "../../../../../services/UndoStack";

const RenderActionsMenu = ({ onToggleArMode, onToggleFullScreen }) => {
  if (!productConfigurator.configuration) {
    return null;
  }

  const [autoRotate, setAutoRotation] = useState(
    productConfigurator.configuration.model.controls.autoRotate
  );

  const takePhotoModuleActive = useModules("CONFIGURATOR.TAKE_PHOTO");
  const arModuleActive = useModules("CONFIGURATOR.AR_MODE");
  const undoRedoModuleActive = useModules("CONFIGURATOR.UNDO_REDO");
  const fullScreenModuleActive = useModules("CONFIGURATOR.FULL_SCREEN");

  const handleUndoStack = (action) => {
    switch (action) {
      case "redo":
        undoStack.redo();
        break;
      case "undo":
      default:
        undoStack.undo();
        break;
    }
  };

  const handleToggleRotation = () => {
    productConfigurator.toggleAutoRotation();
    setAutoRotation(
      productConfigurator.sceneRenderer.sceneControls.controls.autoRotate
    );
  };

  const handleResetCam = () => {
    productConfigurator.resetCamPosition();
    setAutoRotation(
      productConfigurator.sceneRenderer.sceneControls.controls.autoRotate
    );
  };

  const handleSavePhoto = () => {
    productConfigurator.sceneRenderer.getSnap(
      1000,
      1000,
      (dataPhoto) => {
        let link = document.createElement("a");
        document.body.append(link);
        link.download = `${
          productConfigurator.configuration.model.id
        }-${Date.now()}.jpeg`;
        link.href = `data:${dataPhoto}`;
        link.click();
        document.body.removeChild(link);
        setAutoRotation(
          productConfigurator.sceneRenderer.sceneControls.controls.autoRotate
        );
      },
      false
    );

    // productConfigurator.sceneRenderer.getMultipleSnaps(1000, 1000,(dataPhotos)=>{
    // 	dataPhotos.forEach((dataPhoto)=>{
    // 		let link = document.createElement('a');
    // 		document.body.append(link);
    // 		link.download = `${productConfigurator.configuration.model.id}-${Date.now()}.jpeg`;
    // 		link.href = `data:${dataPhoto}`;
    // 		link.click();
    // 		document.body.removeChild(link);
    // 		setAutoRotation(productConfigurator.sceneRenderer.sceneControls.controls.autoRotate);
    // 	})
    // });
  };

  const handleFullScreen = () => {
    onToggleFullScreen();
  };

  const handleOnToggleArMode = () => {
    setAutoRotation(false);
    onToggleArMode();
  };

  return (
    <div className="render-action-menu-container">
      <div className="render-action-menu-buttons-wrapper">
        {undoRedoModuleActive && (
          <button
            onClick={handleUndoStack.bind(this, "undo")}
            disabled={undoStack.current < 0}
          >
            <img src="imgs/icon_undo.svg" alt="" />
          </button>
        )}
        {undoRedoModuleActive && (
          <button
            onClick={handleUndoStack.bind(this, "redo")}
            disabled={undoStack.stack.length - undoStack.current <= 1}
          >
            <img src="imgs/icon_redo.svg" alt="" />
          </button>
        )}
        <button onClick={handleToggleRotation}>
          <img src="imgs/icon_3d_rotate.svg" alt="" />
        </button>
        <button onClick={handleResetCam}>
          <img src="imgs/reset-position-icon.svg" alt="" />
        </button>
        {arModuleActive && (
          <button onClick={handleOnToggleArMode}>AR Mode</button>
        )}
        {fullScreenModuleActive && (
          <button onClick={handleFullScreen}>
            <img src="imgs/full-screen-icon.svg" alt="" />
          </button>
        )}
        {takePhotoModuleActive && (
          <button onClick={handleSavePhoto}>
            <img src="imgs/icon_camera_render.svg" alt="" />
          </button>
        )}
      </div>
    </div>
  );
};

export default RenderActionsMenu;
