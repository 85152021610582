import { ARUtils, ARPerspectiveCamera, ARView } from "three.ar.js";

class ArModule {
  constructor() {
    this.arUtils = undefined;
    this.arPrespectiveCamera = undefined;
    this.arView = undefined;
  }
  async init(renderer) {
    try {
      const display = await ARUtils.getARDisplay();
      console.log("done!:", display);
      this.arView = new ARView(display, renderer);
    } catch (err) {
      console.log("error:", err);
    }
  }
}

export default ArModule;
