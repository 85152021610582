import {
  PointLight,
  HemisphereLight,
  PointLightHelper,
  HemisphereLightHelper,
} from "three";

class SceneLightSet {
  constructor() {
    this.lights = [];
    this.lightHelpers = [];
    this.showLightHelpers = false;
    this.lightSet = [
      {
        type: "pointLight",
        position: { x: 5.5, y: 0, z: 5.5 },
        intensity: 0.5,
        color: "#FFFFFF",
      },
      {
        type: "pointLight",
        position: { x: -5.5, y: 5, z: -5.5 },
        intensity: 0.5,
        color: "#FFFFFF",
      },
      {
        type: "pointLight",
        position: { x: -25.5, y: 5, z: -95.5 },
        intensity: 0.1,
        color: "#FFFFFF",
      },
      {
        type: "hemisphereLight",
        initColor: "rgb(255,255,255)",
        endColor: "rgb(20,20,20)",
        intensity: 1,
      },
    ];
  }

  setIlumination(scene, lightSet = this.lightSet) {
    for (let i = 0; i < lightSet.length; i++) {
      const dataLight = lightSet[i];
      switch (dataLight.type) {
        case "hemisphereLight":
          const hemisphereLight = new HemisphereLight(
            dataLight.initColor,
            dataLight.endColor,
            dataLight.intensity
          );
          scene.add(hemisphereLight);
          this.lights.push(hemisphereLight);
          const hemisphereLightHelper = new HemisphereLightHelper(
            hemisphereLight,
            5
          );
          this.lightHelpers.push(hemisphereLightHelper);
          break;
        case "pointLight":
        default:
          const light = new PointLight(dataLight.color); //0xffeedd
          light.position.set(
            dataLight.position.x,
            dataLight.position.y,
            dataLight.position.z
          );
          light.intensity = dataLight.intensity;
          scene.add(light);
          this.lights.push(light);
          const lightHelper = new PointLightHelper(light, 1);
          lightHelper.visible = this.showLightHelpers;
          this.lightHelpers.push(lightHelper);
          scene.add(lightHelper);
          break;
      }
    }
  }

  destroy(scene) {
    for (let i = 0, j = this.lights.length; i < j; i++) {
      scene.remove(this.lights[i]);
    }
    for (let i = 0, j = this.lightHelpers.length; i < j; i++) {
      scene.remove(this.lightHelpers[i]);
    }
    this.lights = [];
    this.lightHelpers = [];
  }
}

export default SceneLightSet;
