import React from "react";
import useDrawerCollections from "../../../hooks/useDrawerCollections";
import "./burger-btn-styles.scss";

const index = () => {
  const [isActive, setIsActive] = useDrawerCollections();

  const handleBurgerClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div>
      <button onClick={handleBurgerClick} className="burger-btn">
        <img src="imgs/burger_btn.svg" alt="" />
      </button>
    </div>
  );
};

export default index;
