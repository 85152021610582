import React from "react";
import useModules from "../../../../../hooks/useModules";
import useActivityTrack from "../../../../../hooks/useActivityTrack";
import productConfigurator from "../../../../../services/ProductConfigurator";
import SmallSpinner from "../../../../blocks/SmallSpinner";
import usePDF from "../../../../../hooks/usePDF";
import { useParams } from "react-router-dom";

const SavePdfBtn = ({ myDesignId }) => {
  const createPdfModuleActive = useModules("CONFIGURATOR.CREATE_PDF");
  const [action, errorAction, fetchingAction, registerAction] =
    useActivityTrack();
  const [pdfData, pdfError, pdfFetching, { createPdf }] = usePDF(null);
  const params = useParams();

  if (!createPdfModuleActive) {
    return null;
  }
  const handleSavePdf = () => {
    registerAction("DOWNLOADED_PDF", {
      downloadPdf: true,
      product: params.product,
      myDesignId: myDesignId,
    });
    productConfigurator.sceneRenderer.getSnap(500, 500, (dataPhoto) => {
      const pdfInfo = {
        customer: {
          city: "Barcelona",
          company: "Dimo Visual Creatives S.L.",
          customer_name: "Dani",
          date: "2020-01-10T17:35:39.526Z",
          edit_date: "2020-01-10T17:35:39.526Z",
          email: "dani@dimo.cat",
          id_customer: "string",
          phone: "646863201",
        },
        date: "2020-01-10T17:35:39.526Z",
        edit_date: "2020-01-10T17:35:39.526Z",
        id_project: "4d0e9e19-2d03-4ff2-8489-eb61680ce1ca",
        id_user: "string",
        model_id: productConfigurator.configuration.model.id,
        name_model: productConfigurator.configuration.model.name,
        price: 0,
        project_name: "projectName",
        selected_options: productConfigurator.getCurrentConfiguration(),
        show_price: false,
        snap: dataPhoto,
      };
      createPdf(productConfigurator.configuration.model.id, pdfInfo);
    });
  };
  return (
    <button onClick={handleSavePdf} className="btn btn-with-icon">
      {pdfFetching && <SmallSpinner />}
      {!pdfFetching && (
        <>
          <img
            src="imgs/sheet_icon_grey.svg"
            className="icon normal-state"
            alt=""
          />
          <img
            src="imgs/sheet_icon_white.svg"
            className="icon hover-state"
            alt=""
          />
          <span className="label">Download My Design</span>
        </>
      )}
    </button>
  );
};

export default SavePdfBtn;
