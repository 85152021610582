import { Scene, PerspectiveCamera, WebGLRenderer, Color } from "three";
import SceneLightSet from "./SceneLightSet";
import SceneControls from "./SceneControls";
import SceneMaterials from "./SceneMaterials";
import RendererSnap from "./RendererSnap";
import ArModule from "./ArModule";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";

class SceneRendererSrv {
  constructor() {
    this.bgColor = "#e9e4e0";
    this.camera = undefined;
    this.initialCamPos = undefined;
    this.scene = undefined;
    this.renderer = undefined;
    this.container = undefined;
    this.sceneControls = new SceneControls();
    this.sceneLightSet = new SceneLightSet();
    this.sceneMaterials = new SceneMaterials();
    this.rendererSnap = new RendererSnap();
    this.arModule = new ArModule();
    this.model = undefined;
    this.reqID = undefined;
    this.isPaused = false;
  }

  init() {
    this.container = document.getElementById("renderer-cont");
    // SET CAMERA
    this.camera = new PerspectiveCamera(
      45,
      this.container.innerWidth / this.container.innerHeight,
      1,
      20000
    );
    this.camera.position.set(-10, 5, 15);

    // SET SCENE
    this.scene = new Scene();
    this.scene.background = new Color(this.bgColor);

    //SET LIGHTSET
    this.sceneLightSet.setIlumination(this.scene);

    // SET RENDERER
    this.renderer = new WebGLRenderer({
      alpha: true,
      preserveDrawingBuffer: true,
      antialias: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.offsetWidth, window.offsetHeight);

    this.container.appendChild(this.renderer.domElement);

    // SET CONTROLS
    this.sceneControls.init(this.camera, this.renderer.domElement);

    this.animate();
  }

  updateCanvasSize() {
    this.renderer.setSize(
      this.container.offsetWidth,
      this.container.offsetHeight
    );
    this.camera.aspect =
      this.container.offsetWidth / this.container.offsetHeight;
    this.camera.updateProjectionMatrix();
  }

  loadModel(modelUrl, _progressCallback, _endCallback) {
    const loader = new ColladaLoader();
    loader.load(
      modelUrl,
      (collada) => {
        //this.unloadModel();
        if (!this.model) {
          this.model = collada.scene;
        }
        this.scene.add(collada.scene);
        if (typeof _endCallback === "function") {
          return _endCallback(collada.scene);
        }
      },
      (progress) => {
        if (typeof _progressCallback === "function") {
          return _progressCallback(progress);
        }
      }
    );
  }

  render(time) {
    this.renderer.render(this.scene, this.camera);
  }

  animate(time) {
    if (this.sceneControls) {
      this.sceneControls.update();
    }
    this.render(time);
    this.reqID = window.requestAnimationFrame(this.animate.bind(this, time));
  }

  togglePause() {
    this.isPaused = this.isPaused ? false : true;
    if (!this.isPaused) {
      this.animate();
    }
  }

  setBgTransparent(showBg) {
    if (showBg) {
      return (this.scene.background = null);
    }
    return (this.scene.background = new Color(this.bgColor));
  }

  enableArMode() {
    this.setBgTransparent(true);
    this.sceneControls.enableDevideControls();
  }

  disableArMode() {
    this.setBgTransparent(false);
    this.sceneControls.enableControls();
    this.resetCamPosition();
  }

  pause() {
    this.isPaused = true;
  }

  resume() {
    this.isPaused = false;
    this.animate();
  }

  getMaterialByObjectName(meshName) {
    let meshObject = this.model.getObjectByName(meshName);
    if (meshObject) {
      return meshObject.material;
    }
    meshObject = this.scene.getObjectByName(meshName);
    if (meshObject) {
      return meshObject.material;
    }
    throw new Error("THERE IS NO MESHOBJECT FOR ID: " + meshName);
  }

  toggleAutoRotation() {
    //this.controls.autoRotate = !this.controls.autoRotate;
    this.sceneControls.toggleAutoRotation();
  }

  resetCamPosition() {
    if (this.sceneControls.controls.autoRotate) {
      this.sceneControls.toggleAutoRotation();
    }
    this.camera.position.set(
      this.initialCamPos.x,
      this.initialCamPos.y,
      this.initialCamPos.z
    );
    //this.controls.target.set(0,0,0);
    this.sceneControls.resetControlsPosition();
  }

  setCameraPosition(cameraPosition) {
    this.initialCamPos = this.initialCamPos || cameraPosition;
    this.camera.position.set(
      cameraPosition.x,
      cameraPosition.y,
      cameraPosition.z
    );
  }

  setControls(controls) {
    this.sceneControls.setControls(controls);
  }

  async getSnap(widthSnap, heightSnap, _callback, resetCamPos = true) {
    try {
      if (resetCamPos) {
        this.resetCamPosition();
      }
      const imgData = await this.rendererSnap.getSnap(
        widthSnap,
        heightSnap,
        this
      );
      this.updateCanvasSize();
      if (typeof _callback === "function") {
        _callback(imgData);
      }
    } catch (err) {
      throw err;
    }
  }

  async getMultipleSnaps(widthSnap, heightSnap, _callback) {
    try {
      this.resetCamPosition();
      const imgsData = await this.rendererSnap.getMultipleSnaps(
        widthSnap,
        heightSnap,
        this
      );
      this.updateCanvasSize();
      if (typeof _callback === "function") {
        _callback(imgsData);
      }
    } catch (err) {
      throw err;
    }
  }

  unloadModel() {
    if (this.model) {
      this.scene.remove(this.model);
      this.model = undefined;
      delete this.model;
    }
  }

  setMaterial(meshName, matProps) {
    const material = this.getMaterialByObjectName(meshName);
    this.sceneMaterials.setMaterial(material, matProps);
  }

  setVisibles(visibilityConf) {
    for (let i = 0, j = visibilityConf.visible.targets.length; i < j; i++) {
      const visibleMeshName = visibilityConf.visible.targets[i];
      const meshVisible = this.model.getObjectByName(visibleMeshName);
      if (!meshVisible) {
        throw new Error("THERE IS NO MESHOBJECT FOR ID: " + visibleMeshName);
      }
      meshVisible.visible = true;
    }

    for (let i = 0, j = visibilityConf.hidden.targets.length; i < j; i++) {
      const hiddenMeshName = visibilityConf.hidden.targets[i];
      const hiddenMesh = this.model.getObjectByName(hiddenMeshName);
      if (!hiddenMesh) {
        throw new Error("THERE IS NO MESHOBJECT FOR ID: " + hiddenMeshName);
      }
      hiddenMesh.visible = false;
    }
  }

  destroySceneRenderer() {
    this.unloadModel();
    this.sceneLightSet.destroy(this.scene);
    this.initialCamPos = undefined;
    if (this.container) {
      this.container.removeChild(this.renderer.domElement);
      this.container = undefined;
    }
    cancelAnimationFrame(this.reqID);
    this.reqID = undefined;
    this.scene = null;
    delete this.scene;
    this.sceneControls.destroy();
    this.camera = null;
    delete this.camera;
    if (this.renderer) {
      this.renderer.forceContextLoss();
      this.renderer.domElement = null;
      this.renderer = null;
      delete this.renderer;
    }
    console.log("DESTROYED", this.scene, this.renderer);
  }
}

export default SceneRendererSrv;
