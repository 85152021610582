import React from "react";
import JwPagination from "jw-react-pagination";
import "./pagination-styles.scss";

const index = ({ myDesigns, onChangePage, pageSize = 10 }) => {
  return (
    <div className="pagination-container text-center mt-2">
      <JwPagination
        items={myDesigns}
        onChangePage={onChangePage}
        pageSize={pageSize}
        labels={{
          first: "<<",
          last: ">>",
          previous: "<",
          next: ">",
        }}
      />
    </div>
  );
};

export default index;
