import React, { createContext, useReducer } from "react";
import { CLOSE_SESSION, SET_USER_DATA } from "./UserContextConstants";

const UserContext = createContext();

const initialState = {
  token: process.env.REACT_APP_MASTER_TOKEN || null,
  id: null,
  tokenExpiration: undefined,
  name: "",
  email: "",
  role: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, ...action.userData };
    case CLOSE_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const UserContextProvider = (props) => {
  const [userState, userDispatch] = useReducer(reducer, initialState);
  const userValue = { userState, userDispatch };
  return (
    <UserContext.Provider value={userValue}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
