import React from "react";
import { Route, Switch } from "react-router-dom";
import ViewRoute from "./ViewRoute";
import View404 from "./views/View404";
import routes from "../routes";

const Routing = (props) => {
  return (
    <Switch>
      {routes.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            exact
            component={({ match, history }) => {
              return (
                <ViewRoute
                  route={route}
                  match={match}
                  history={history}
                  {...props}
                />
              );
            }}
          />
        );
      })}
      <Route component={View404} />
    </Switch>
  );
};

export default Routing;
