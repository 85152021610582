import { useState } from "react";
import axios from "axios";
import useUser from "./useUser";
import { EP } from "../services/EP";
import { SET_USER_DATA } from "../context/UserContextConstants";

const useCheckSession = () => {
  const dispatchUser = useUser()[1];
  const [fetching, setFetching] = useState(false);
  const [redirectExternalLogin, setRedirectToExternalLogin] = useState(false);
  const checkSession = async (id, token) => {
    setFetching(true);
    try {
      const response = await axios.get(
        `${EP}session/init?id=${id}&token=${token}`
      );
      setFetching(false);
      dispatchUser({
        type: SET_USER_DATA,
        userData: {
          token: response.data,
          id: id,
        },
      });
    } catch (err) {
      setFetching(false);
      setRedirectToExternalLogin(true);
      console.log(err);
    }
  };

  return { checkSession, fetching, redirectExternalLogin };
};

export default useCheckSession;
