import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Spinner from "../../blocks/Spinner";
import useNotifications from "../../../hooks/useNotifications";
import useUser from "../../../hooks/useUser";
import useCollections from "../../../hooks/useCollections";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import "./home-view-styles.scss";

const HomeView = ({ route }) => {
  const [userState] = useUser();

  if (!userState.token) {
    return <Redirect to="/login" />;
  }

  const [setNotification] = useNotifications();
  const [collections, error, isLoading, getCollections] = useCollections();
  const [breadcrumbData, setBreadcrumbData] = useBreadcrumb();

  useEffect(() => {
    if (error) {
      setNotification({ title: "Error", text: error, type: "error" });
    }
  }, [error]);

  useEffect(() => {
    setBreadcrumbData({
      levels: [
        {
          name: route.name,
          link: route.path,
        },
      ],
      isLoading: false,
    });
    getCollections();
  }, []);

  const getBgByCollectionId = (collectionId) => {
    switch (collectionId) {
      case "waiting-areas":
        return "wating-areas-snap.jpg";
      case "cinema":
        return "cinema-snap.jpg";
      case "fixed-seating":
        return "fixed-seating-snap.jpg";
      case "stadium-and-areas":
      default:
        return "stadium-and-arenas-snap.jpg";
    }
  };

  return (
    <div className="home-view-container">
      {isLoading && <Spinner />}
      {!isLoading && (
        <>
          <h1 className="h4 text-red mb-3">Select the product segment</h1>
          <div className="segments-block-container">
            {collections.map((collection) => {
              return (
                <Link
                  to={`/${collection.id}/products`}
                  key={collection.id}
                  className={`segment-block block-link`}
                >
                  <span className="segment-block__title">
                    {collection.title}
                  </span>
                  <img
                    className="segment-block__bg"
                    src={`imgs/${getBgByCollectionId(collection.id)}`}
                    alt=""
                  />
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default HomeView;
