import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import VersionBadge from "../VersionBadge";
import useDrawerCollections from "../../../hooks/useDrawerCollections";
import useCollections from "../../../hooks/useCollections";
import useUser from "../../../hooks/useUser";
import "./drawer-menu-collections-styles.scss";

const DrawerMenuCollections = () => {
  const [isActive, setIsActive] = useDrawerCollections();
  const [userData] = useUser();
  const [collections, error, isLoading, getCollections] = useCollections();
  const history = useHistory();

  const handleCloseBtn = () => {
    setIsActive(false);
  };

  const handleClickCollection = (collectionId) => {
    setIsActive(false);
    history.push(`/${collectionId}/products`);
  };

  useEffect(() => {
    if (userData.token) {
      getCollections();
    }
  }, []);

  return (
    <div className={`drawer-menu-collections ${isActive ? "active" : ""}`}>
      <div className="drawer-menu-wrapper">
        <button onClick={handleCloseBtn} className="close-btn">
          <img src="imgs/close_btn.svg" alt="" />
        </button>
        <ul className="menu">
          {collections.map((collection) => {
            return (
              <li key={collection.id}>
                <button
                  onClick={handleClickCollection.bind(this, collection.id)}
                >
                  {collection.title}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="text-right mt-2">
          <VersionBadge />
        </div>
      </div>
      <div className="drawer-bg" onClick={handleCloseBtn}></div>
    </div>
  );
};

export default DrawerMenuCollections;
