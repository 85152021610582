import React from "react";
import productConfigurator from "../../../../../services/ProductConfigurator";
import "./technical-specs-styles.scss";

const TechnicalSpecs = () => {
  if (!productConfigurator.configuration) {
    return null;
  }
  return (
    <div className="technical-specs-container d-flex">
      <div className="col col-tech-info">
        <ul className="techincal-specs-list">
          {productConfigurator.configuration.model.functional_specifications.map(
            (functionalSpec, i) => {
              return <li key={`functional-spec-${i}`}>{functionalSpec}</li>;
            }
          )}
        </ul>
        <div>
          {productConfigurator.configuration.datasheet && (
            <a
              href={productConfigurator.configuration.datasheet}
              target="_blank"
              className="btn btn-corporate btn-filled btn-with-icon"
            >
              <img
                src="imgs/save_icon_white.svg"
                alt=""
                className="icon normal-state"
              />
              <img
                src="imgs/save_icon_red.svg"
                alt=""
                className="icon hover-state"
              />
              <span className="label">Download Datasheet</span>
            </a>
          )}
        </div>
      </div>
      <div className="col col-tech-image">
        <div>
          <img
            src={productConfigurator.configuration.model.technical_image}
            className="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default TechnicalSpecs;
