import React from "react";
import Breadcrumb from "../Breadcrumb";
import Seachform from "../Searchform";
import BurgerBtn from "../BurgerBtn";
import { useRouteMatch } from "react-router-dom";
import "./bottom-bar-styles.scss";

const BottomBar = () => {
  const isMyDesignsView = useRouteMatch("/my-designs");
  const isSessionEndView = useRouteMatch("/session-end");

  if (isMyDesignsView || isSessionEndView) {
    return null;
  }

  return (
    <div className="bottom-bar-container">
      <Breadcrumb />
      <div className="search-and-drawer-cont">
        <Seachform />
        <BurgerBtn />
      </div>
    </div>
  );
};

export default BottomBar;
