import { useState } from "react";
import { EP } from "../services/EP";
import useHttp from "./useHttp";

const useMyDesigns = (initialValue = []) => {
  const [data, error, fetching, { get, post, del, put }] =
    useHttp(initialValue);

  const [myDesigns, setMyDesigns] = useState(initialValue);

  const getMyDesigns = async (designId) => {
    if (!designId) {
      const myDesignData = await get(`${EP}my-design/`);
      setMyDesigns(myDesignData);
      return myDesignData;
    }
    const myDesignData = await get(`${EP}my-design/${designId}`);
    setMyDesigns(myDesignData);
  };

  const addMyDesign = async (myDesignData) => {
    const savedMyDesign = await post(`${EP}my-design/`, myDesignData);
    setMyDesigns(savedMyDesign);
    return savedMyDesign;
  };

  const deleteMyDesign = async (myDesignId) => {
    setMyDesigns(await del(`${EP}my-design/${myDesignId}`));
  };

  const updateMyDesign = async (myDesignId, myDesignData) => {
    const currentDate = new Date();
    myDesignData.modificationDate = currentDate.toISOString();
    setMyDesigns(await put(`${EP}my-design/${myDesignId}`, myDesignData));
  };

  return [
    myDesigns,
    error,
    fetching,
    { getMyDesigns, addMyDesign, deleteMyDesign, updateMyDesign, setMyDesigns },
  ];
};

export default useMyDesigns;
