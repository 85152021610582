import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../../blocks/Breadcrumb";
import BurgerBtn from "../../../../blocks/BurgerBtn";
import useForm from "react-hook-form";
import "./my-designs-filter-form.scss";

const MyDesignsFilterForm = ({ onChangeParams }) => {
  const { register, handleSubmit, errors } = useForm();
  const [params, setParams] = useState({
    keyWord: "",
    orderBy: "date",
  });

  const onSubmit = (data) => {
    setParams({
      ...params,
      keyWord: data.keyWord,
    });
  };

  const handleOnChange = (e) => {
    setParams({
      ...params,
      orderBy: e.target.value,
    });
  };

  useEffect(() => {
    onChangeParams(params);
  }, [params]);

  return (
    <div className="my-design-filter-form-container d-flex align-items-center justify-content-between">
      <Breadcrumb />
      <div className="d-flex align-items-center justify-content-between">
        <form onSubmit={handleSubmit(onSubmit)} className="form-inline">
          <input
            type="text"
            name="keyWord"
            ref={register({})}
            className="input-search-my-design form-control d-inline-block with-border-down mx-2"
            placeholder="Write a keyword"
          />
          <button
            type="submit"
            className="btn btn-corporate d-inline-block mx-2"
          >
            Find
          </button>
          <label htmlFor="">Order by</label>
          <select
            name="orderBy"
            id=""
            defaultValue={params.orderBy}
            className="form-control d-inline-block ml-1"
            onChange={handleOnChange}
          >
            <option value="project-name">Project Name</option>
            <option value="product">Product</option>
            <option value="date">Date</option>
          </select>
        </form>
        <BurgerBtn />
      </div>
    </div>
  );
};

export default MyDesignsFilterForm;
