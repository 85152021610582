import React from "react";
import TopBar from "../../blocks/TopBar";
import BottomBar from "../../blocks/BottomBar";
import { useLocation } from "react-router-dom";
import DrawerMenuCollections from "../../blocks/DrawerMenuCollections";
import "./main-template-styles.scss";
import useUser from "../../../hooks/useUser";

const MainTemplate = ({ children }) => {
  const [dataUser] = useUser();
  const location = useLocation();
  let customViewClass = "";

  switch (location.pathname) {
    case "/my-designs":
      customViewClass = "p-0";
      break;
    default:
      break;
  }

  if (!dataUser.token) {
    return <div>{children}</div>;
  }

  return (
    <div className="main-template-container">
      <TopBar />
      <BottomBar />
      <div className={`children-container ${customViewClass}`}>{children}</div>
      <DrawerMenuCollections />
    </div>
  );
};

export default MainTemplate;
