class ConfiguratorRestrictions {
  applyRestrictions(
    _optionNameSelected,
    _optionValueSelected,
    prevChangeOptionSelected,
    productConfigurator
  ) {
    let restrictNotes = [];

    for (
      let i = 0,
        j = productConfigurator.configuration.restriction_options.length;
      i < j;
      i++
    ) {
      let restriction =
        productConfigurator.configuration.restriction_options[i];
      switch (restriction.restriction_type) {
        case "config_option":
          let conditionsMatch = [];
          if (
            !restriction.onChanged ||
            restriction.onChanged.option_name === _optionNameSelected
          ) {
            for (let n = 0, m = restriction.if.length; n < m; n++) {
              let _if = restriction.if[n];
              switch (_if.operator) {
                case "==":
                  if (
                    productConfigurator.getCurrentConfiguration()[
                      _if.option_name
                    ] === _if.option_value
                  ) {
                    conditionsMatch.push("in");
                  } else {
                    conditionsMatch.push("out");
                    break;
                  }
                  break;

                case "!=":
                  if (
                    productConfigurator.getCurrentConfiguration()[
                      _if.option_name
                    ] !== _if.option_value
                  ) {
                    conditionsMatch.push("in");
                  } else {
                    conditionsMatch.push("out");
                    break;
                  }
                  break;

                case "one-of":
                  if (
                    _if.option_value.indexOf(
                      productConfigurator.getCurrentConfiguration()[
                        _if.option_name
                      ]
                    ) >= 0
                  ) {
                    conditionsMatch.push("in");
                  } else {
                    conditionsMatch.push("out");
                    break;
                  }
                  break;

                case "none-of":
                  if (
                    _if.option_value.indexOf(
                      productConfigurator.getCurrentConfiguration()[
                        _if.option_name
                      ]
                    ) >= 0
                  ) {
                    conditionsMatch.push("out");
                  } else {
                    conditionsMatch.push("in");
                    break;
                  }
                  break;

                default:
                  break;
              }
            }
          } else {
            conditionsMatch.push("out");
          }

          if (conditionsMatch.indexOf("out") === -1) {
            if (restriction.description) {
              if (typeof restriction.description === "string") {
                restrictNotes.push(restriction.description);
              } else if (typeof restriction.description === "object") {
                if (restriction.description.show) {
                  restrictNotes.push(restriction.description.text);
                }
              }
            }

            for (let n = 0, m = restriction["then"].length; n < m; n++) {
              let valueToApply = undefined;
              switch (restriction["then"][n].option_value) {
                case "PRE_CHANGE_VALUE":
                  valueToApply = prevChangeOptionSelected;
                  break;
                case null:
                  valueToApply =
                    productConfigurator.getCurrentConfiguration()[
                      restriction["then"][n].option_name
                    ];
                  break;
                default:
                  valueToApply = restriction["then"][n].option_value;
                  break;
              }

              productConfigurator.changeConfigurationByOption(
                restriction["then"][n].option_name,
                valueToApply,
                true
              );

              if (restriction["then"][n].visibility_combination) {
                productConfigurator.changeModelVisibility(
                  restriction["then"][n].visibility_combination
                );
              }
            }
          }

          break;

        case "change_mat_id":
          if (_optionNameSelected === restriction.onChange["option_name"]) {
            let validOnChange = true;
            let onChangeOptionName = restriction.onChange["option_name"];
            let onChangeOptionValue = restriction.onChange["option_value"];
            if (onChangeOptionValue) {
              if (_optionValueSelected !== onChangeOptionValue) {
                validOnChange = false;
              }
            } else {
              onChangeOptionValue =
                productConfigurator.getCurrentConfiguration()[
                  onChangeOptionName
                ];
            }
            if (validOnChange) {
              let conditionsMatch = false;
              switch (restriction.if.operator) {
                case "==":
                  if (
                    productConfigurator.getCurrentConfiguration()[
                      restriction.if.option_name
                    ] === restriction.if.option_value
                  ) {
                    conditionsMatch = true;
                  }
                  break;
                case "!=":
                  if (
                    productConfigurator.getCurrentConfiguration()[
                      restriction.if.option_name
                    ] !== restriction.if.option_value
                  ) {
                    conditionsMatch = true;
                  }
                  break;
                case "one-of":
                  if (
                    restriction.if.option_value.indexOf(
                      productConfigurator.getCurrentConfiguration()[
                        restriction.if.option_name
                      ]
                    ) >= 0
                  ) {
                    conditionsMatch = true;
                  }
                  break;

                case "none-of":
                  if (
                    restriction.if.option_value.indexOf(
                      productConfigurator.getCurrentConfiguration()[
                        restriction.if.option_name
                      ]
                    ) < 0
                  ) {
                    conditionsMatch = true;
                  }
                  break;

                default:
                  break;
              }
              if (conditionsMatch) {
                let optionToUpdate =
                  productConfigurator.configuration.config_options[
                    restriction.then.option_name
                  ][restriction.then.option_value];
                let _mat_id_from_option_value = restriction.then
                  .mat_id_from_option_value
                  ? restriction.then.mat_id_from_option_value
                  : productConfigurator.configuration.currentConfiguration[
                      restriction.then.mat_id_from_option_name
                    ];
                optionToUpdate.materials.id =
                  productConfigurator.configuration.config_options[
                    restriction.then.mat_id_from_option_name
                  ][_mat_id_from_option_value].materials.id;
                productConfigurator.changeMaterialConf({
                  id: optionToUpdate.materials.id,
                  target: optionToUpdate.materials.target,
                });
              }
            }
          }
          break;

        case "change_model_prop":
          let _if = restriction["if"];
          switch (_if.operator) {
            case "==":
              if (
                productConfigurator.getCurrentConfiguration()[
                  _if.option_name
                ] === _if.option_value
              ) {
                productConfigurator.configuration.model[
                  restriction["prop_name"]
                ] = restriction["prop_value"];
              }
              break;
            case "!=":
              if (
                productConfigurator.getCurrentConfiguration()[
                  _if.option_name
                ] !== _if.option_value
              ) {
                productConfigurator.configuration.model[
                  restriction["prop_name"]
                ] = restriction["prop_value"];
              }
              break;
            default:
              break;
          }
          break;

        default:
          break;
      }
    }

    return restrictNotes;
  }
}

// const configuratorRestrictions = new ConfiguratorRestrictions();

export default ConfiguratorRestrictions;
