import React from "react";
import ReactDOM from "react-dom";
// import registerServiceWorker from "./registerServiceWorker";
import unregister from "./registerServiceWorker";
import App from "./components/App";
import "./SCSS/index.scss";

ReactDOM.render(<App />, document.getElementById("root"));

// registerServiceWorker();
unregister();
