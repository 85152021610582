import { useState } from "react";
import useHttp from "./useHttp";
import { EP } from "../services/EP";

const useProduct = (initalValue = null) => {
  const [products, setProducts] = useState(initalValue);

  const [data, error, fetching, { get }] = useHttp(initalValue);

  const fetchProducts = (productId, onGetProduct) => {
    get(
      productId ? `${EP}data/model/get/${productId}` : `${EP}data/model/list`,
      (responseData) => {
        if (productId) {
          while (typeof responseData.restriction_options === "string") {
            responseData.restriction_options = JSON.parse(
              responseData.restriction_options
            );
          }
          responseData.model.url = responseData.model.url.replace("daes/", "");
          responseData.model.url = `${EP}daes/kxvW5ntJQ8cJhzGu?file=${responseData.model.url}`;
          responseData.model.id = productId;
        }
        setProducts(responseData);
        if (typeof onGetProduct === "function") {
          onGetProduct(responseData);
        }
      }
    );
  };

  return [products, error, fetching, fetchProducts];
};

export default useProduct;
