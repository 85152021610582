import React, { useContext, useEffect } from "react";
import ForceRedirect from "./ForceRedirect";
import { Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import useActivityTrack from "../hooks/useActivityTrack";
import useModules from "../hooks/useModules";

const ViewRoute = props => {
  const { userState } = useContext(UserContext);
  const [action, error, fetchingAction, registerAction] = useActivityTrack();
  const checkModule = useModules();

  useEffect(() => {
    let dataView = {
      view: props.route.name
    };
    switch (props.route.path) {
      case "/:collection/products":
        dataView = {
          ...dataView,
          segment: props.match.params.collection
        };
        break;
      case "/search-result/:searchText":
        dataView = {
          ...dataView,
          segment: props.match.params.collection,
          searchedTerm: props.match.params.searchText
        };
        break;
      case "/configurator/:product":
        dataView = {
          ...dataView,
          product: props.match.params.product
        };
        break;
      default:
        break;
    }
    registerAction("LOADED_VIEW", dataView);
  }, [props]);

  if (props.route.private && !userState.token) {
    return <ForceRedirect ifUserLogged={false} to="/" />;
  }

  if (props.route.module && !checkModule(props.route.module)) {
    return <Redirect to="/" />;
  }

  return React.createElement(props.route.component, {
    route: props.route,
    match: props.match,
    history: props.history
  });
};

export default ViewRoute;
