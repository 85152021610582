import React, { useEffect } from "react";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import { Accordeon, Handler, Panel, Content } from "../../blocks/Accordeon";

const HelpView = ({ route }) => {
  const [breadcrumbData, setBreadcrumbData] = useBreadcrumb();
  useEffect(() => {
    setBreadcrumbData({
      levels: [
        {
          name: route.name,
          link: route.path,
        },
      ],
      isLoading: false,
    });
  }, []);

  return (
    <div>
      <Accordeon collapseAll={true}>
        <Panel expanded={true}>
          <Handler>Title 1</Handler>
          <Content>Acoordeon content 1</Content>
        </Panel>
        <Panel>
          <Handler>Title 2</Handler>
          <Content>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quod
              tempora eveniet quam quaerat libero laborum, consequuntur dolor
              rem illo possimus assumenda doloribus quae repellat, veritatis
              eaque soluta sed! Expedita, vero.
            </p>
            <p>
              Hic at magni sed ducimus aliquid labore porro voluptates aut eos
              blanditiis cumque assumenda quaerat dicta omnis, dolorum odio
              mollitia, beatae accusantium nesciunt molestias. Sunt beatae
              assumenda ea officia doloribus!
            </p>
            <p>
              Veniam provident atque alias perferendis vero eaque quibusdam,
              amet consequuntur temporibus officia earum nisi distinctio odit
              aliquid asperiores omnis deleniti culpa. Tempora officiis sequi
              laudantium qui nam quo quae commodi!
            </p>
            <p>
              Quos, voluptatum minus consectetur laudantium ipsam quae?
              Accusantium omnis ipsum doloremque ad est voluptate repellendus,
              expedita veniam ipsa modi fugiat hic quos minima! Architecto omnis
              officiis, culpa saepe fugit officia.
            </p>
            <p>
              Vel, repudiandae rerum error voluptatibus, porro aperiam placeat
              est, sit perferendis expedita eveniet nisi natus id aliquid
              deserunt nihil aspernatur esse possimus? Reiciendis ex ad
              perspiciatis placeat dolores libero quis?
            </p>
            <p>
              Voluptates pariatur adipisci culpa molestiae! Sit repudiandae,
              exercitationem, aspernatur voluptas odit in dolorum qui est
              officiis eius ex ipsum assumenda, esse temporibus voluptatibus
              dignissimos laboriosam accusamus porro laudantium quibusdam dolor!
            </p>
            <p>
              Officia aspernatur eligendi ullam laborum laboriosam, quibusdam
              autem blanditiis perspiciatis beatae ratione, pariatur adipisci
              debitis reprehenderit nostrum veniam eaque maxime nesciunt itaque
              commodi! Nulla dolores neque laborum recusandae fugit odit!
            </p>
            <p>
              Officiis laudantium, esse reprehenderit illo minus hic sunt sed
              dolores culpa nam amet nihil obcaecati, facilis modi libero
              voluptates nobis? Error ducimus in omnis dicta aliquam, dolorum
              molestias doloribus ut.
            </p>
            <p>
              Velit tenetur quae itaque maiores hic eveniet tempora assumenda
              quaerat alias vel quia, distinctio voluptate earum reprehenderit.
              Consequatur et repudiandae adipisci quod sunt quo cumque
              perspiciatis eaque, qui suscipit est.
            </p>
            <p>
              Quaerat, porro soluta molestias atque, nisi quibusdam, rem odio
              sapiente magni quos magnam accusamus libero facere quod delectus
              totam necessitatibus est! Dolorum odit doloremque sunt. Quas
              voluptatem corporis odio illum.
            </p>
          </Content>
        </Panel>
      </Accordeon>
    </div>
  );
};

export default HelpView;
