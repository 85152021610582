import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import routes from "../../../routes";
import useUser from "../../../hooks/useUser";
import useNotifications from "../../../hooks/useNotifications";
import useModules from "../../../hooks/useModules";
import useMyDesigns from "../../../hooks/useMyDesigns";
import Modal from "../Modal";
import ContactForm from "../ContactForm";
import envelopIcon from "../../../imgs/envelop.svg";
// import { CLOSE_SESSION } from "../../../context/UserContextConstants";
import "./menu-style.scss";

const MenuElement = ({
  name,
  path,
  myDesignNotification,
  myDesignsQuantity,
}) => {
  return (
    <li
      className={`menu-element ${
        path === "/my-designs" &&
        `my-design-menu-element-cont ${myDesignNotification && "active"}`
      }`}
    >
      {path === "/my-designs" && (
        <span className={`my-designs-menu-toastr`}>{myDesignNotification}</span>
      )}
      <Link to={path}>
        {name}
        {path === "/my-designs" ? (
          <span className="badge-my-designs-quantity">{myDesignsQuantity}</span>
        ) : null}
      </Link>
    </li>
  );
};

const Menu = () => {
  const checkModule = useModules();
  const [userState] = useUser();
  const [showContactModal, setShowContactModal] = useState(false);
  const { myDesignNotification } = useNotifications()[1];
  const { myDesignsQuantity, setDesignsQuantity } = useNotifications()[2];
  const { getMyDesigns, setMyDesigns } = useMyDesigns([])[3];

  const fetchMyDesigns = async () => {
    const myDesigns = await getMyDesigns();
    if (myDesigns) {
      setDesignsQuantity(myDesigns.length);
    }
    setMyDesigns(null);
  };

  useEffect(() => {
    fetchMyDesigns();
  }, []);

  if (!userState.token) {
    return null;
  }

  // const closeSession = () => {
  //   userDispatch({
  //     type: CLOSE_SESSION,
  //   });
  // };

  const handleToggleContactModal = () => {
    setShowContactModal((prevShowContactModal) => {
      return !prevShowContactModal;
    });
  };

  return (
    <>
      <div className="main-menu-container">
        <ul>
          <li className="contact-btn-wrapper">
            <button onClick={handleToggleContactModal}>
              <img src={envelopIcon} alt="" />
            </button>
          </li>
          {routes.map((route, i) => {
            if (route.hiddeInMenu) {
              return null;
            }
            if (route.module && !checkModule(route.module)) {
              return null;
            }
            return (
              <MenuElement
                key={i}
                name={route.name}
                path={route.path}
                myDesignNotification={myDesignNotification}
                myDesignsQuantity={myDesignsQuantity}
              />
            );
          })}
          {/* <li>
          <button onClick={closeSession}>Close Session {userState.name}</button>
        </li> */}
        </ul>
      </div>
      <Modal
        show={showContactModal}
        onCloseModal={handleToggleContactModal}
        title="How can we help you?"
      >
        <ContactForm />
      </Modal>
    </>
  );
};

export default Menu;
