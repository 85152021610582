import React, { useState, useEffect } from "react";
import productConfigurator from "../../../../../services/ProductConfigurator";
import materials from "../../../../../data/materials/materials.json";
import "./configurator-menu-styles.scss";

const PrimaryMenu = ({ onClickElementZero }) => {
  return Object.keys(productConfigurator.configuration.config_menu).map(
    (key, i) => {
      return (
        <button
          key={key}
          className="primary-menu-btn"
          onClick={onClickElementZero.bind(this, key)}
        >
          <span className="label-config-name text-red font-bold">
            {productConfigurator.configuration.config_options[key].data.title}:
          </span>
          <span className="label-config-value ml-1">
            {
              productConfigurator.configuration.config_options[key][
                productConfigurator.getCurrentConfiguration()[key]
              ].data.title
            }
          </span>
        </button>
      );
    }
  );
};

const SnapImage = ({ configOption }) => {
  if (!configOption || !configOption.materials) {
    return null;
  }
  return (
    <div
      className={`snap-container`}
      style={{
        backgroundColor:
          configOption &&
          configOption.materials &&
          materials[configOption.materials.id].color,
      }}
    >
      {configOption &&
        configOption.materials &&
        materials[configOption.materials.id].map && (
          <img
            src={materials[configOption.materials.id].map.url}
            alt=""
            className="img-fluid"
          />
        )}
    </div>
  );
};

const MenuLevelZero = ({ levelZero, onClickBack, onClickElement }) => {
  return (
    <div
      className={`level-menu ${levelZero ? "in" : ""}`}
      style={{ zIndex: `10`, overflowY: levelZero ? "scroll" : "" }}
    >
      <div className="level-menu-wrapper">
        <button onClick={onClickBack} className="back-btn">
          Back
        </button>
        {levelZero &&
          Object.keys(
            productConfigurator.configuration.config_menu[levelZero]
          ).map((key, i) => {
            const hasChildren =
              productConfigurator.configuration.config_menu[levelZero][key]
                .children;
            const isActive =
              productConfigurator.getCurrentConfiguration()[levelZero] ===
              productConfigurator.configuration.config_menu[levelZero][key]
                .data["option_value"];
            return (
              <button
                key={key}
                className={`
                  btn-level-menu
                  ${hasChildren ? "with-arrow" : ""}
                  ${!hasChildren && isActive ? "active" : ""}
                `}
                onClick={onClickElement.bind(this, key)}
              >
                <SnapImage
                  configOption={
                    !hasChildren &&
                    productConfigurator.configuration.config_options[levelZero][
                      productConfigurator.configuration.config_menu[levelZero][
                        key
                      ].data.option_value
                    ]
                  }
                />
                <span className="label-btn">
                  {
                    productConfigurator.configuration.config_menu[levelZero][
                      key
                    ].data.title
                  }
                </span>
              </button>
            );
          })}
      </div>
    </div>
  );
};

const MenuLevel = ({ levelZero, middleKey, onClickBack, onClickElement }) => {
  return (
    <div
      className={`level-menu ${middleKey ? "in" : ""}`}
      style={{ zIndex: `20`, overflowY: middleKey ? "scroll" : "" }}
    >
      <div className="level-menu-wrapper">
        <button onClick={onClickBack} className="back-btn">
          Back
        </button>
        {levelZero &&
          middleKey &&
          productConfigurator.configuration.config_menu[levelZero][
            middleKey
          ].children.map((child) => {
            const isActive =
              productConfigurator.getCurrentConfiguration()[levelZero] ===
              child.option_value;
            return (
              <button
                key={child.option_value}
                onClick={onClickElement.bind(this, child.option_value)}
                className={`btn-level-menu ${isActive ? "active" : ""}`}
              >
                <SnapImage
                  configOption={
                    productConfigurator.configuration.config_options[levelZero][
                      child.option_value
                    ]
                  }
                />
                <span className={`label-btn`}>
                  {
                    productConfigurator.configuration.config_options[levelZero][
                      child.option_value
                    ].data.title
                  }
                </span>
              </button>
            );
          })}
      </div>
    </div>
  );
};

const ConfiguratorMenu = ({ lastConfigUpdate, onChangeConfiguration }) => {
  const [levelZero, setLevelZero] = useState(null);
  const [middleKey, setMiddleKey] = useState(null);

  useEffect(() => {
    if (!lastConfigUpdate) {
      setLevelZero(null);
      setMiddleKey(null);
    }
  }, [lastConfigUpdate]);

  if (!lastConfigUpdate) {
    return null;
  }

  return (
    <div className="configurator-menu-container">
      <PrimaryMenu
        onClickElementZero={(keyZero) => {
          setLevelZero(keyZero);
        }}
      />
      <MenuLevelZero
        levelZero={levelZero}
        onClickBack={() => {
          setLevelZero(null);
        }}
        onClickElement={(keyElement) => {
          if (
            !productConfigurator.configuration.config_menu[levelZero][
              keyElement
            ].children
          ) {
            return onChangeConfiguration(
              levelZero,
              productConfigurator.configuration.config_menu[levelZero][
                keyElement
              ].data.option_value
            );
          }
          setMiddleKey(keyElement);
        }}
      />
      <MenuLevel
        levelZero={levelZero}
        middleKey={middleKey}
        onClickElement={(optionValue) => {
          onChangeConfiguration(levelZero, optionValue);
        }}
        onClickBack={() => {
          setMiddleKey(null);
        }}
      />
    </div>
  );
};

export default ConfiguratorMenu;
