import { useContext } from "react";
import { NotificationsContext } from "../context/NotificationsContext";

const useNotifications = () => {
  const [
    setNotification,
    { myDesignNotification, setMyDesginNotification },
    { myDesignsQuantity, setDesignsQuantity },
  ] = useContext(NotificationsContext);

  return [
    setNotification,
    { myDesignNotification, setMyDesginNotification },
    { myDesignsQuantity, setDesignsQuantity },
  ];
};

export default useNotifications;
