import React, { useState } from "react";
import Modal from "../../../../blocks/Modal";
import ContactForm from "../../../../blocks/ContactForm";
import useModule from "../../../../../hooks/useModules";
import RelatedProducts from "../RelatedProducts";
import TechnicalSpecs from "../TechnicalSpecs";
import productConfigurator from "../../../../../services/ProductConfigurator";
import "./more-info-styles.scss";

const MoreInfo = ({ lastConfigUpdate }) => {
  if (!productConfigurator.configuration) {
    return null;
  }

  const isRelatedProductsModuleActive = useModule(
    "CONFIGURATOR.RELATED_PRODUCTS"
  );

  const [showNeedAdviceModal, setShowAdviceModal] = useState(false);
  const [moreInfoShown, setMoreInfoShown] = useState("technical-specs");

  const handleToggleModalNeedHelp = () => {
    setShowAdviceModal(!showNeedAdviceModal);
  };

  return (
    <div className="more-info-container">
      <div className="btns-group">
        <button
          onClick={handleToggleModalNeedHelp}
          className="btn btn-corporate"
        >
          Need advice?
        </button>
        <div className="tech-and-related-btns-container">
          <button
            onClick={setMoreInfoShown.bind(this, "technical-specs")}
            className={`${moreInfoShown === "technical-specs" ? "active" : ""}`}
          >
            Technical specifications
          </button>
          {isRelatedProductsModuleActive && (
            <button
              onClick={setMoreInfoShown.bind(this, "related-products")}
              className={`${
                moreInfoShown === "related-products" ? "active" : ""
              }`}
            >
              Related products
            </button>
          )}
        </div>
      </div>
      {moreInfoShown === "technical-specs" && (
        <TechnicalSpecs lastConfigUpdate={lastConfigUpdate} />
      )}
      {moreInfoShown === "related-products" && (
        <RelatedProducts lastConfigUpdate={lastConfigUpdate} />
      )}
      <Modal
        show={showNeedAdviceModal}
        onCloseModal={handleToggleModalNeedHelp}
        title="Need advice?"
      >
        <ContactForm
          product={{
            productId: productConfigurator.configuration.model.id,
            name: productConfigurator.configuration.model.name,
          }}
        />
      </Modal>
    </div>
  );
};

export default MoreInfo;
