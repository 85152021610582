import React, { useEffect, useRef, useState } from "react";
import "./bg-video-styles.scss";

const BgVideo = ({ show, className }) => {
  // const audioInputSelect = useRef();
  // const audioOutputSelect = useRef();
  // const videoInputSource = useRef();
  const video = useRef();

  const [deviceId, setDeviceId] = useState(null);
  // const selectors = [audioInputSelect, audioOutputSelect, videoInputSource];

  // const attachSinkId = (element, sinkId) => {
  //   if (typeof element.sinkId !== "undefined") {
  //     element
  //       .setSinkId(sinkId)
  //       .then(function() {
  //         console.log("Success, audio output device attached: " + sinkId);
  //       })
  //       .catch(function(error) {
  //         let errorMessage = error;
  //         if (error.name === "SecurityError") {
  //           errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
  //         }
  //         console.error(errorMessage);
  //         // Jump back to first output device in the list as it's the default.
  //         audioOutputSelect.current.selectedIndex = 0;
  //       });
  //   } else {
  //     console.warn("Browser does not support output device selection.");
  //   }
  // };

  // const changeAudioDestination = () => {
  //   let audioDestination = audioOutputSelect.current.value;
  //   attachSinkId(video, audioDestination);
  // };

  const handleError = (error) => {
    alert(error);
    console.log("navigator.getUserMedia error: ", error);
  };

  const gotStream = (stream) => {
    window.stream = stream;
    video.current.srcObject = stream;
    return navigator.mediaDevices.enumerateDevices();
  };

  const gotDevices = (deviceInfos, e) => {
    // let values = selectors.map(function(select) {
    //   return select.value;
    // });
    // selectors.forEach(function(select) {
    //   while (select.firstChild) {
    //     select.removeChild(select.firstChild);
    //   }
    // });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      let deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === "videoinput") {
        if (/back|trasera|Back|Trasera/.test(deviceInfo.label)) {
          // videoInputSource.current.value = option.value;
          setDeviceId(deviceInfo.deviceId);
          console.log("back camera found by label!");
        }
      }
      // let option = document.createElement("option");
      // option.value = deviceInfo.deviceId;
      // if (deviceInfo.kind === "audioinput") {
      //   option.text =
      //     deviceInfo.label ||
      //     "microphone " + (audioInputSelect.current.length + 1);
      //   audioInputSelect.current.appendChild(option);
      // } else if (deviceInfo.kind === "audiooutput") {
      //   option.text =
      //     deviceInfo.label ||
      //     "speaker " + (audioOutputSelect.current.length + 1);
      //   audioOutputSelect.current.appendChild(option);
      // } else if (deviceInfo.kind === "videoinput") {
      //   option.text =
      //     deviceInfo.label || "camera " + (videoInputSource.current.length + 1);
      //   videoInputSource.current.appendChild(option);
      // } else {
      //   console.log("Some other kind of source/device: ", deviceInfo);
      // }
    }

    // selectors.forEach(function(select, selectorIndex) {
    //   if (
    //     Array.prototype.slice.call(select.childNodes).some(function(n) {
    //       return n.value === values[selectorIndex];
    //     })
    //   ) {
    //     select.value = values[selectorIndex];
    //   }
    // });
    // videoInputSource.current.childNodes.forEach((option, optionIndex) => {
    //   console.log(option.text, option.value, optionIndex);
    //   if (/back|trasera|Back|Trasera/.test(option.text)) {
    //     videoInputSource.current.value = option.value;
    //     console.log("back camera found by label!");
    //   }
    // });
  };

  const start = async (e) => {
    if (window.stream) {
      window.stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    // let audioSource = audioInputSelect.current.value;
    // let videoSource = videoInputSource.current.value;
    let constraints = {
      // audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      video: { deviceId: deviceId ? { exact: deviceId } : undefined },
    };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const deviceInfos = await gotStream(stream);
    gotDevices(deviceInfos, e);
    // .then(() => {
    //   //let savConst = localStorageService.get('videoConstrains');
    //   if (e === "FIRST") {
    //     start("SECOND");
    //   }
    // })
    // .catch(handleError);
  };

  const onMount = async () => {
    // audioInputSelect.current.onChange = start;
    // audioOutputSelect.current.onChange = changeAudioDestination;
    // videoInputSource.current.onchange = start;
    try {
      // if (navigator.mediaDevices) {
      const devices = await navigator.mediaDevices.enumerateDevices();
      gotDevices(devices);
      start();
      // } else {
      // throw new Error("media devices not available");
      // }
    } catch (err) {
      handleError(err);
    }
    //start("FIRST");
  };

  useEffect(() => {
    if (deviceId) {
      start();
    }
  }, [deviceId]);

  useEffect(() => {
    if (show) {
      onMount();
      return () => {
        if (window.stream) {
          window.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      };
    }
  }, [show]);

  if (!show) {
    return null;
  }
  return (
    <div className={`bg-video-container ${className}`}>
      {/* <div>
        <div className="select">
          <label htmlFor="audioSource">Audio input source: </label>
          <select ref={audioInputSelect}></select>
        </div>

        <div className="select">
          <label htmlFor="audioOutput">Audio output destination: </label>
          <select ref={audioOutputSelect}></select>
        </div>

        <div className="select">
          <label htmlFor="videoSource">Video source: </label>
          <select ref={videoInputSource}></select>
        </div>
      </div> */}
      <video ref={video} muted playsInline autoPlay></video>
    </div>
  );
};

export default BgVideo;
