import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DeviceOrientationControls } from "three/examples/jsm/controls/DeviceOrientationControls";

class SceneControls {
  constructor() {
    this.controls = undefined;
    this.deviceControls = undefined;
    this.camera = undefined;
  }
  init(camera, domElement) {
    this.camera = camera;
    this.controls = new OrbitControls(this.camera, domElement);
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.3;
    this.controls.enableZoom = true;
    this.controls.enableKeys = false;
    this.controls.zoomSpeed = 0.5;
    this.controls.rotateSpeed = 0.5;
    this.controls.screenSpacePanning = true;

    this.deviceControls = new DeviceOrientationControls(this.camera);
    this.deviceControls.enabled = false;

    //this.controls.maxPolarAngle = Math.PI / 3;
    //this.controls.maxPolarAngle = (Math.PI / 2);

    this.setControls({
      autoRotate: true,
      maxPolarAngle: 0,
      minPolarAngle: 0,
      minDistance: 0,
      maxDistance: 3000,
      enablePan: true,
    });
  }

  setControls(controls) {
    this.controls.autoRotate =
      typeof controls.autoRotate === "boolean"
        ? controls.autoRotate
        : this.controls.autoRotate;
    this.controls.maxPolarAngle = controls.maxPolarAngle
      ? controls.maxPolarAngle
      : this.controls.maxPolarAngle;
    this.controls.minPolarAngle = controls.minPolarAngle
      ? controls.minPolarAngle
      : this.controls.minPolarAngle;
    this.controls.minDistance = controls.minDistance
      ? controls.minDistance
      : this.controls.minDistance;
    this.controls.maxDistance = controls.maxDistance
      ? controls.maxDistance
      : this.controls.maxDistance;
    this.controls.enablePan =
      typeof controls.enablePan === "boolean"
        ? controls.enablePan
        : this.controls.enablePan;
  }

  toggleAutoRotation() {
    this.controls.autoRotate = !this.controls.autoRotate;
  }

  resetControlsPosition() {
    this.controls.target.set(0, 0, 0);
  }

  resetDeviceControlsPosition() {
    this.camera.position.set(0, 0, 50);
  }

  update() {
    if (this.deviceControls.enabled) {
      return this.deviceControls.update();
    }
    this.controls.update();
  }

  enableDevideControls() {
    this.controls.enabled = false;
    this.deviceControls.enabled = true;
    this.resetDeviceControlsPosition();
  }

  enableControls() {
    this.controls.enabled = true;
    this.deviceControls.enabled = false;
    this.resetControlsPosition();
  }

  destroy() {
    this.camera = undefined;
    if (this.controls) {
      this.controls.dispose();
      this.controls = null;
    }
    if (this.deviceControls) {
      this.deviceControls.dispose();
      this.deviceControls = null;
    }
  }
}

export default SceneControls;
