import { Box3, Vector3 } from "three";
import SceneRendererSrv from "./SceneRendererService";
import ConfiguratorRestrictions from "./ConfiguratorRestrictions";

class ProductConfigurator {
  constructor() {
    this.configuration = undefined;
    this.sceneRenderer = new SceneRendererSrv();
    this.configuratorRestrictions = new ConfiguratorRestrictions();
    this.lastConfigurations = {};
  }

  init() {
    this.sceneRenderer.init();
  }

  loadConfiguration(configuration, materials, _progressCallback, _endCallback) {
    this.configuration = configuration;
    this.configuration.materials = materials;
    this.configuration.currentConfiguration = {};

    this.sceneRenderer.loadModel(
      this.configuration.model.url,
      (progress) => {
        if (typeof _progressCallback === "function") {
          return _progressCallback(progress);
        }
      },
      (modelData) => {
        this.launchConfiguration(this.configuration.init.config_options);

        for (let initFixedOptionName in this.configuration.init.fixed) {
          let initFixedOption =
            this.configuration.init.fixed[initFixedOptionName];
          if (initFixedOption.visibility_combinations) {
            this.changeModelVisibility(initFixedOption.visibility_combinations);
          }
          if (initFixedOption.materials) {
            this.changeMaterialConf(initFixedOption.materials);
          }
        }

        if (typeof this.configuration.model.controls === "object") {
          this.sceneRenderer.setControls(this.configuration.model.controls);

          if (
            typeof this.configuration.model.controls.initialCamPos !== "object"
          ) {
            let sceneSize = new Box3()
              .setFromObject(modelData)
              .getSize(new Vector3());

            this.configuration.model.controls.initialCamPos = {
              x: sceneSize.x < 35 ? -10 : -sceneSize.x, //-10 => (-1*(sceneSize.x*1.317))
              y: sceneSize.x < 35 ? 5 : 30, //5
              z: sceneSize.x < 35 ? 15 : 50, // 15 => (sceneSize.z*1.479)
            };
          }
          this.sceneRenderer.setCameraPosition(
            this.configuration.model.controls.initialCamPos
          );
        }
        if (typeof _endCallback === "function") {
          return _endCallback(
            this.configuration.config_menu,
            this.configuration.config_options,
            this.configuration,
            modelData
          );
        }
      }
    );
  }

  launchConfiguration(savedConfiguration, _callback) {
    let deprecatedConfigsNotes = [];
    for (let optionName in savedConfiguration) {
      if (
        !this.configuration.config_options[optionName] ||
        !this.configuration.config_options[optionName][
          savedConfiguration[optionName]
        ]
      ) {
        deprecatedConfigsNotes.push(
          `La opcion seleccionada por: ${optionName} ya no esta disponible`
        );
        console.log(
          `La opcion seleccionada por: ${optionName} ya no esta disponible`
        );
        if (this.configuration.config_options[optionName]) {
          this.configuration.currentConfiguration[optionName] =
            this.configuration.init.config_options[optionName];
        }
      } else {
        this.configuration.currentConfiguration[optionName] =
          savedConfiguration[optionName];
      }
    }
    for (let optionName in this.configuration.currentConfiguration) {
      this.changeConfigurationByOption(
        optionName,
        this.configuration.currentConfiguration[optionName]
      );
    }
    if (typeof _callback === "function") {
      _callback(
        this.configuration.currentConfiguration,
        deprecatedConfigsNotes
      );
    }
  }

  getCurrentConfiguration() {
    return this.configuration.currentConfiguration;
  }

  changeMaterialConf(materialName) {
    this.configuration.materials[materialName.id].map = this.configuration
      .materials[materialName.id].map
      ? this.configuration.materials[materialName.id].map
      : null;
    this.configuration.materials[materialName.id].bumpMap = this.configuration
      .materials[materialName.id].bumpMap
      ? this.configuration.materials[materialName.id].bumpMap
      : null;
    this.configuration.materials[materialName.id].envMap = this.configuration
      .materials[materialName.id].envMap
      ? this.configuration.materials[materialName.id].envMap
      : null;

    this.sceneRenderer.setMaterial(
      materialName.target,
      this.configuration.materials[materialName.id]
    );
  }

  changeModelVisibility(visibilitCombinationName) {
    let visibilityConf =
      this.configuration.visibility_combinations[visibilitCombinationName];

    if (!visibilityConf) {
      throw new Error(
        `Does not exists visibilit combination for ${visibilitCombinationName}`
      );
    }

    this.sceneRenderer.setVisibles(visibilityConf);
  }

  changeConfigurationByOption(
    _optionName,
    _optionSelected,
    _noRestrictions,
    _callback
  ) {
    let restrictNotes = undefined;
    if (_optionName && _optionSelected) {
      let _prevChangeOptionSelected =
        this.configuration.currentConfiguration[_optionName];
      let optionSelected =
        this.configuration.config_options[_optionName][_optionSelected];
      if (optionSelected.visibility_combinations) {
        this.changeModelVisibility(optionSelected.visibility_combinations);
      }
      if (optionSelected.materials) {
        this.changeMaterialConf(optionSelected.materials);
      }
      this.configuration.currentConfiguration[_optionName] = _optionSelected;
      if (!_noRestrictions) {
        restrictNotes = this.configuratorRestrictions.applyRestrictions(
          _optionName,
          _optionSelected,
          _prevChangeOptionSelected,
          this
        );
      }
      if (typeof _callback === "function") {
        _callback(optionSelected, restrictNotes);
      }
    } else {
      throw new Error("There are some data missing");
    }
  }

  updateCanvasSize() {
    this.sceneRenderer.updateCanvasSize();
  }

  toggleAutoRotation = function () {
    this.sceneRenderer.toggleAutoRotation();
  };

  resetCamPosition() {
    this.sceneRenderer.resetCamPosition();
  }

  destroyConfigurator(lastProductId) {
    this.sceneRenderer.destroySceneRenderer();
    this.configuration = undefined;
    delete this.configuration;
  }
}

const productConfigurator = new ProductConfigurator();

export default productConfigurator;
