import React, { useState, useRef, useEffect } from "react";
import "./accordeon-styles.scss";

const Accordeon = ({ children, collapseAll }) => {
  const [expandedPanels, setExpandendPannels] = useState(
    children.map((child) => {
      return child.props.expanded ? true : false;
    })
  );

  const handleOnToggleCollapse = (index) => {
    let nextExpandedPanels = [...expandedPanels];
    if (collapseAll) {
      nextExpandedPanels = expandedPanels.map(() => {
        return false;
      });
    }
    nextExpandedPanels[index] = !expandedPanels[index];
    setExpandendPannels(nextExpandedPanels);
  };

  return (
    <div>
      {children.map((child, i) => {
        return (
          <Panel
            key={i}
            onToggleCollapse={handleOnToggleCollapse.bind(this, i)}
            expanded={expandedPanels[i]}
          >
            {child.props.children}
          </Panel>
        );
      })}
    </div>
  );
};

const Panel = ({ children, expanded, onToggleCollapse }) => {
  return (
    <div>
      {children.map((child, i) => {
        if (child.type.refName === "Handler") {
          return (
            <Handler
              key={i}
              onClick={onToggleCollapse}
              className={child.props.className}
            >
              {child.props.children}
            </Handler>
          );
        }
        return (
          <Content key={i} expanded={expanded}>
            {child.props.children}
          </Content>
        );
      })}
    </div>
  );
};

const Handler = ({ onClick, children, className }) => {
  return (
    <div onClick={onClick} className={className}>
      {children}
    </div>
  );
};

Handler.refName = "Handler";

const Content = ({ expanded, children }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();

  const handleSaveContentSize = () => {
    setContentHeight(contentRef.current.getBoundingClientRect().height + 100);
  };

  useEffect(() => {
    handleSaveContentSize();
    window.addEventListener("resize", handleSaveContentSize);
    window.setTimeout(handleSaveContentSize, 500);
    return () => {
      window.removeEventListener("resize", handleSaveContentSize);
    };
  }, [expanded]);

  return (
    <div
      className="accordeon-content"
      style={{ maxHeight: expanded ? contentHeight : 0 }}
    >
      <div ref={contentRef} className="wrapper-accordeon-content">
        {children}
      </div>
    </div>
  );
};

export { Accordeon, Panel, Handler, Content };
