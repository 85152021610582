import React from "react";
import "./modal-styles.scss";

const Modal = ({ children, show, onCloseModal, title }) => {
  return (
    <div className={`modal ${show ? "active" : ""}`}>
      <div className="content-wrapper">
        <div className="header-modal">
          <button onClick={onCloseModal} className="close-btn">
            <img src="imgs/close_btn.svg" alt="" />
          </button>
          {title && <h2 className="text-center mb-0 font-light">{title}</h2>}
        </div>
        <div className="modal-body bg-grey-light">{show && children}</div>
      </div>
      <div className="modal-bg" onClick={onCloseModal}></div>
    </div>
  );
};

export default Modal;
