class RendererSnap {
  getSnap(widthSnap, heightSnap, sceneRenderer) {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        try {
          const strMime = "image/jpeg";
          const divHolder = document.createElement("div");
          const containerSize = sceneRenderer.container.getBoundingClientRect();
          divHolder.style.width = `${containerSize.width}px`;
          divHolder.style.height = `${containerSize.height}px`;
          sceneRenderer.container.style = `
                width: ${widthSnap / window.devicePixelRatio}px;
                height:${heightSnap / window.devicePixelRatio}px;
                opacity: 0;
                position: absolute;
            `;
          sceneRenderer.container.parentNode.prepend(divHolder);
          sceneRenderer.updateCanvasSize();
          window.setTimeout(() => {
            const imgData =
              sceneRenderer.renderer.domElement.toDataURL(strMime);
            sceneRenderer.container.parentNode.removeChild(divHolder);
            sceneRenderer.container.style = undefined;
            resolve(imgData);
          }, 500);
        } catch (err) {
          reject(err);
        }
      }, 300);
    });
  }

  async getMultipleSnaps(widthSnap, heightSnap, sceneRenderer) {
    try {
      sceneRenderer.setCameraPosition({ x: 100, y: 0, z: 0 });
      const topImg = await this.getSnap(widthSnap, heightSnap, sceneRenderer);
      sceneRenderer.setCameraPosition({ x: 0, y: 100, z: 0 });
      const leftImg = await this.getSnap(widthSnap, heightSnap, sceneRenderer);
      sceneRenderer.setCameraPosition({ x: 0, y: 0, z: 100 });
      const rightImg = await this.getSnap(widthSnap, heightSnap, sceneRenderer);
      return [topImg, leftImg, rightImg];
    } catch (err) {
      throw err;
    }
  }
}

export default RendererSnap;
