import React, { useState } from "react";
import useForm from "react-hook-form";
import Modal from "../../../../blocks/Modal";
import productConfigurator from "../../../../../services/ProductConfigurator";
import useModules from "../../../../../hooks/useModules";
import useMyDesigns from "../../../../../hooks/useMyDesigns";
import useNotifications from "../../../../../hooks/useNotifications";
import useActivityTrack from "../../../../../hooks/useActivityTrack";
import Spinner from "../../../../blocks/Spinner";

const SaveMyDesignBtn = ({ myDesign, getMyDesigns }) => {
  const myDesignsModuleActive = useModules("MY_DESINGS");
  if (!myDesignsModuleActive) {
    return null;
  }
  const { register, handleSubmit, errors, setValue } = useForm();
  const [dataPhoto, setDataPhoto] = useState(null);
  const [myDesigns, error, fetchingDesign, { addMyDesign, updateMyDesign }] =
    useMyDesigns();

  const { setMyDesginNotification } = useNotifications()[1];
  const { setDesignsQuantity } = useNotifications()[2];
  const registerAction = useActivityTrack()[3];

  const onSubmitMyDesign = async (data) => {
    const saveAsNewMyDesign = data.saveAsNewMyDesign === "true";
    const myDesignData = {
      title: data.titleDesign,
      snap: dataPhoto,
      productId: productConfigurator.configuration.model.id,
      config: productConfigurator.getCurrentConfiguration(),
    };
    if (myDesign && !saveAsNewMyDesign) {
      await updateMyDesign(myDesign.mydesignId, {
        ...myDesignData,
        mydesignId: myDesign.mydesignId,
      });
      getMyDesigns(myDesign.mydesignId);
    } else {
      const savedMyDesign = await addMyDesign(myDesignData);
      setDesignsQuantity((prevMyDesignsQuantity) => {
        return prevMyDesignsQuantity + 1;
      });
      registerAction("SAVE_MYDESIGN", {
        product: productConfigurator.configuration.model.id,
        myDesignId: savedMyDesign.mydesignId,
      });
    }
    setDataPhoto(null);
    setMyDesginNotification(
      myDesign && !saveAsNewMyDesign
        ? "My design updated correctly!"
        : "My design saved correctly!"
    );
  };

  const handleToggleModalSaveDesign = () => {
    if (dataPhoto) {
      return setDataPhoto(null);
    }
    productConfigurator.sceneRenderer.getSnap(500, 500, (dataPhoto) => {
      setDataPhoto(dataPhoto);
    });
  };

  return (
    <>
      <button
        onClick={handleToggleModalSaveDesign}
        className="btn btn-corporate btn-filled btn-with-icon"
      >
        <img
          src="imgs/save_icon_white.svg"
          className="icon normal-state"
          alt=""
        />
        <img src="imgs/save_icon_red.svg" className="icon hover-state" alt="" />
        <span className="label">Save My Design</span>
      </button>
      <Modal
        show={dataPhoto}
        onCloseModal={handleToggleModalSaveDesign}
        title="Save to My Designs"
      >
        {fetchingDesign && <Spinner />}
        {!fetchingDesign && (
          <form onSubmit={handleSubmit(onSubmitMyDesign)} className="form">
            <input
              type="text"
              placeholder="Project name"
              name="titleDesign"
              className="form-control mb-2 with-border-down bg-transparent"
              defaultValue={myDesign && myDesign.title}
              ref={register({ required: true })}
            />
            <input type="hidden" ref={register({})} name="saveAsNewMyDesign" />
            {errors.titleDesign && (
              <span className="text-red">Project name required!</span>
            )}
            <div>
              {dataPhoto && (
                <img src={dataPhoto} alt="render" className="d-block mx-auto" />
              )}
            </div>
            <h4 className="text-red mt-3">
              {productConfigurator.configuration.model.name}
            </h4>
            <div>
              <ul className="list-unstyled">
                {(() => {
                  const currentConfiguration =
                    productConfigurator.getCurrentConfiguration();
                  return Object.keys(currentConfiguration).map(
                    (optionKey, i) => {
                      const optionValue = currentConfiguration[optionKey];
                      return (
                        <li key={i}>
                          <span className="font-bold text-red">
                            {
                              productConfigurator.configuration.config_options[
                                optionKey
                              ].data.title
                            }
                            :
                          </span>{" "}
                          <span className="text-grey-dark">
                            {
                              productConfigurator.configuration.config_options[
                                optionKey
                              ][optionValue].data.title
                            }
                          </span>
                        </li>
                      );
                    }
                  );
                })()}
              </ul>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-corporate btn-filled">
                {myDesign ? "Update" : "Save"}
              </button>
              {myDesign && (
                <button
                  type="submit"
                  className="btn btn-corporate"
                  onClick={() => {
                    setValue("saveAsNewMyDesign", "true");
                  }}
                >
                  Save as a new My Design
                </button>
              )}
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};

export default SaveMyDesignBtn;
