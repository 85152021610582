/*IMPORT COMPONENTS*/
import HomeView from "../components/views/HomeView";
import LoginView from "../components/views/LoginView";
import SessionEndView from "../components/views/SessionEndView";
import HelpView from "../components/views/HelpView";
import ProductsView from "../components/views/ProductsView";
import ConfiguratorView from "../components/views/ConfiguratorView";
// import SaveOfferView from '../components/views/SaveOfferView';
import SearchResults from "../components/views/SearchResultsView";
import MyDesignsView from "../components/views/MyDesignsView";
import ArView from "../components/views/ArView";
/*END IMPORT COMPONENTS*/

const routes = [
  {
    path: "/",
    component: HomeView,
    private: false,
    name: "Segments",
    hiddeInMenu: true,
  },
  {
    path: "/login/:id?/:token?/:productId?",
    component: LoginView,
    private: false,
    name: "LoginView",
    hiddeInMenu: true,
  },
  {
    path: "/session-end/:redirectType?",
    component: SessionEndView,
    private: false,
    name: "SessionEndView",
    hiddeInMenu: true,
  },
  {
    path: "/:collection/products",
    component: ProductsView,
    private: true,
    name: "ProductsView",
    hiddeInMenu: true,
  },
  {
    path: "/configurator/:product",
    component: ConfiguratorView,
    private: true,
    name: "ConfiguratorView",
    hiddeInMenu: true,
  },
  {
    path: "/configurator/:product/:designId",
    component: ConfiguratorView,
    private: true,
    name: "ConfiguratorViewLoadDesign",
    hiddeInMenu: true,
  },
  {
    path: "/search-result/:searchText",
    component: SearchResults,
    module: "SEARCH_PRODUCT",
    private: true,
    name: "SearchResultsView",
    hiddeInMenu: true,
  },
  // {path: '/save-offer', component: SaveOfferView, private: true, name: 'SaveOfferView', hiddeInMenu: true},
  {
    path: "/my-designs",
    component: MyDesignsView,
    module: "MY_DESINGS",
    private: true,
    name: "My designs",
    hiddeInMenu: false,
  },
  {
    path: "/help",
    component: HelpView,
    module: "HELP_VIEW",
    private: true,
    name: "Help",
    hiddeInMenu: false,
  },
  {
    path: "/ar",
    component: ArView,
    module: "CONFIGURATOR.AR_MODE",
    private: true,
    name: "ArView",
    hiddeInMenu: false,
  },
];

export default routes;
