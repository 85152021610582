import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import Spinner from "../../blocks/Spinner";
import useMyDesigns from "../../../hooks/useMyDesigns";
import MyDesignCard from "./MyDesignViewParts/MyDesignCard";
import MyDesignsFilterForm from "./MyDesignViewParts/MyDesignsFilterForm";
import Pagination from "../../blocks/Pagination";
import useNotifications from "../../../hooks/useNotifications";
import useProduct from "../../../hooks/useProduct";
import "./my-design-view-styles.scss";

const MyDesignsView = ({ route }) => {
  const [breadcrumbData, setBreadcrumbData] = useBreadcrumb();
  const [pageOfItems, setPageOfItems] = useState([]);
  const [filteredMyDesigns, setFilteredMyDesigns] = useState([]);
  const [myDesigns, error, fetching, { getMyDesigns, setMyDesigns }] =
    useMyDesigns();

  const { deleteMyDesign, updateMyDesign } = useMyDesigns(null)[3];

  const { setDesignsQuantity } = useNotifications()[2];
  const { setMyDesginNotification } = useNotifications()[1];

  const [productList, errorProductList, gettingProductList, getProductList] =
    useProduct();

  useEffect(() => {
    getMyDesigns();
    getProductList();
    setBreadcrumbData({
      levels: [
        {
          name: route.name,
          link: route.path,
        },
      ],
      isLoading: false,
    });
  }, []);

  useEffect(() => {
    setFilteredMyDesigns(myDesigns.filter((myDesign) => !myDesign.hide));
  }, [myDesigns]);

  const handleOnDelete = (designId) => {
    deleteMyDesign(designId);
    setMyDesigns(
      myDesigns.filter((myDesign) => {
        return myDesign.mydesignId !== designId;
      })
    );
    setDesignsQuantity((prevMyDesignsQuantity) => {
      return prevMyDesignsQuantity - 1;
    });
    setMyDesginNotification("My design deleted correctly!");
  };

  const handleOnEdit = (design, i, newTitle) => {
    if (design.title === newTitle) {
      return null;
    }
    updateMyDesign(design.mydesignId, { ...design, title: newTitle });
    setMyDesigns(
      myDesigns.map((myDesign) => {
        return {
          ...myDesign,
          title:
            design.mydesignId === myDesign.mydesignId
              ? newTitle
              : myDesign.title,
        };
      })
    );
    setMyDesginNotification("My design edited correctly!");
  };

  const handleOnChangeParams = (params) => {
    setMyDesigns(
      myDesigns
        .map((myDesign) => {
          myDesign.hide = true;
          myDesign.hide =
            myDesign.title.toLowerCase().indexOf(params.keyWord.toLowerCase()) <
            0
              ? true
              : false;
          if (myDesign.hide && productList[myDesign.productId]) {
            myDesign.hide =
              productList[myDesign.productId].model.name
                .toLowerCase()
                .indexOf(params.keyWord.toLowerCase()) < 0
                ? true
                : false;
          }
          return myDesign;
        })
        .sort((a, b) => {
          switch (params.orderBy) {
            case "product":
              return a.productId > b.productId ? 1 : -1;
            // break;
            case "project-name":
              return a.title > b.title ? 1 : -1;
            // break;
            case "date":
            default:
              return a.modificationDate < b.modificationDate ? 1 : -1;
          }
        })
    );
  };

  if (fetching || gettingProductList) {
    return <Spinner />;
  }

  if (error || errorProductList) {
    return <div>{error}</div>;
  }

  if (myDesigns.length <= 0) {
    return (
      <div className="py-5">
        <h3 className="text-center text-black mb-4">
          You have not create any <span className="text-red">My design</span>{" "}
          yet
        </h3>
        <p className="text-center">
          <Link className="btn btn-corporate" to="/">
            Back
          </Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <div>
        <MyDesignsFilterForm onChangeParams={handleOnChangeParams} />
      </div>
      <div
        className={`${
          productList && pageOfItems.length > 0
            ? "my-designs-view-container"
            : ""
        }`}
      >
        {productList &&
          pageOfItems.length > 0 &&
          pageOfItems.map((design, i) => {
            return (
              <div className="my-design-card-wrapper" key={design.mydesignId}>
                <MyDesignCard
                  title={design.title}
                  snap={design.snap}
                  isObsolete={productList[design.productId] ? false : true}
                  modificationDate={design.modificationDate}
                  myDesignId={design.mydesignId}
                  productId={design.productId}
                  config={design.config}
                  productName={
                    productList[design.productId]
                      ? productList[design.productId].model.name
                      : ""
                  }
                  onDelete={handleOnDelete.bind(this, design.mydesignId)}
                  onEdit={handleOnEdit.bind(this, design, i)}
                />
              </div>
            );
          })}
        {productList && pageOfItems.length === 0 && (
          <h2 className="text-red text-center">Sorry, no results found</h2>
        )}
      </div>
      <div
        className={
          productList && pageOfItems.length > 0 ? `bg-grey-lighter py-5` : ""
        }
      >
        <Pagination
          myDesigns={filteredMyDesigns}
          onChangePage={setPageOfItems.bind(this)}
          pageSize={10}
        />
      </div>
    </>
  );
};

export default MyDesignsView;
