import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../blocks/Spinner";
import useCollections from "../../../hooks/useCollections";
import useNotifications from "../../../hooks/useNotifications";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import ProductsListTemplate from "../../templates/ProductsListTemplate";
import ProductCard from "../../blocks/ProductCard";

const ProductsView = (props) => {
  const [collection, error, fetching, getCollection] = useCollections(null);
  const [breadcrumbData, setBreadcrumbData] = useBreadcrumb();
  const [setNotification] = useNotifications();

  useEffect(() => {
    getCollection(props.match.params.collection);
  }, [props.match.params.collection]);

  useEffect(() => {
    if (collection) {
      setBreadcrumbData({
        levels: [
          {
            name: collection.title,
            link: `/${props.match.params.collection}/products`,
          },
        ],
        isLoading: false,
      });
    }
  }, [collection]);

  useEffect(() => {
    if (error) {
      setNotification({ title: "Error", text: error, type: "error" });
    }
  }, [error]);

  return (
    <ProductsListTemplate>
      {(fetching || !collection) && <Spinner />}
      {collection && !collection.models && (
        <div>No hay productos en esta colección</div>
      )}
      {!fetching &&
        collection &&
        collection.models &&
        collection.models.map((product, i) => {
          return (
            <ProductCard
              key={product.code}
              to={`/configurator/${product.code}`}
              snapUrl={product.snap}
              productName={product.name}
            />
          );
        })}
    </ProductsListTemplate>
  );
};

export default ProductsView;
