import React, { createContext, useState, useEffect } from "react";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const NotificationsContext = createContext();
const initialState = null;

const NotificationsContextProvider = (props) => {
  const [notificationState, setNotification] = useState(initialState);
  const [myDesignNotification, setMyDesginNotification] = useState(null);
  const [myDesignsQuantity, setDesignsQuantity] = useState(0);

  useEffect(() => {
    if (notificationState) {
      NotificationManager[notificationState.type](
        notificationState.text,
        notificationState.title
      );
    }
  }, [notificationState]);

  useEffect(() => {
    if (myDesignNotification) {
      window.setTimeout(() => {
        setMyDesginNotification(null);
      }, 3000);
    }
  }, [myDesignNotification]);

  return (
    <>
      <NotificationsContext.Provider
        value={[
          setNotification,
          { myDesignNotification, setMyDesginNotification },
          { myDesignsQuantity, setDesignsQuantity },
        ]}
      >
        {props.children}
      </NotificationsContext.Provider>
      <NotificationContainer />
    </>
  );
};

export { NotificationsContext, NotificationsContextProvider };
