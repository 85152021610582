import React from "react";
import "./spinner-styles.scss";

const Spinner = ({ style }) => {
  return (
    <div style={style} className="spinner-loader">
      Loading
    </div>
  );
};

export default Spinner;
