import React from "react";
import "./product-list-template-styles.scss";

const ProductsListTemplate = ({ children }) => {
  return (
    <div className="products-list-template-container">
      <div className="products-row">{children}</div>
    </div>
  );
};

export default ProductsListTemplate;
