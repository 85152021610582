import { useState } from "react";
import axios from "axios";
import useUser from "./useUser";
import { CLOSE_SESSION } from "../context/UserContextConstants";

const useHttp = (initialVal = null) => {
  const [data, setData] = useState(initialVal);
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [userState, userDispatch] = useUser();

  const get = async (EP, onGetData) => {
    return await fetch("get", EP, null, onGetData);
  };

  const post = async (EP, dataPost, onGetData, specialHeaders) => {
    return await fetch("post", EP, dataPost, onGetData, specialHeaders);
  };

  const del = async (EP, onGetData) => {
    return await fetch("delete", EP, null, onGetData);
  };

  const put = async (EP, dataPut, onGetData) => {
    return await fetch("put", EP, dataPut, onGetData);
  };

  const fetch = async (method, EP, data, onGetData, specialHeaders = {}) => {
    setData(initialVal);
    setError(null);
    setFetching(true);
    let response = undefined;
    let dataResponse = undefined;
    const headers = {
      headers: {
        "Content-Type": "application/json",
        ...specialHeaders,
        "X-Authorization": `${userState.token}`,
      },
    };
    try {
      switch (method) {
        case "get":
        default:
          response = await axios.get(EP, headers);
          dataResponse = response.data || "getdone";
          break;
        case "post":
          response = await axios.post(EP, data, headers);
          dataResponse = response.data || "postdone";
          break;
        case "delete":
          response = await axios.delete(EP, headers);
          dataResponse = response.data || "deletedone";
          break;
        case "put":
          response = await axios.put(EP, data, headers);
          dataResponse = response.data || "putdone";
          break;
      }
      setFetching(false);
      setData(dataResponse);
      if (typeof onGetData === "function") {
        onGetData(dataResponse);
      }
      return dataResponse;
    } catch (err) {
      if (err.response && err.response.status === 403) {
        userDispatch({
          type: CLOSE_SESSION,
        });
        return;
      }
      setFetching(false);
      setError(err.message);
    }
  };

  return [data, error, fetching, { get, post, del, put }];
};

export default useHttp;
