import React, { createContext, useState } from "react";

const BreadcrumbContext = createContext();

const firstState = {
  levels: [],
  isLoading: false,
};

const BreadcrumbContextProvider = (props) => {
  const [breadcrumbData, setBreadcrumbData] = useState(firstState);

  return (
    <BreadcrumbContext.Provider value={{ breadcrumbData, setBreadcrumbData }}>
      {props.children}
    </BreadcrumbContext.Provider>
  );
};

export { BreadcrumbContext, BreadcrumbContextProvider };
