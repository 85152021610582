import React from "react";
import { UserContextProvider } from "./UserContext";
import { NotificationsContextProvider } from "./NotificationsContext";
import { BreadcrumbContextProvider } from "./BreadcrumbContext";
import { DrawerCollectionsContextProvider } from "./DrawerCollectionsContext";
import { ModulesContextProvider } from "./ModulesContext";

const StateContextProvider = (props) => {
  return (
    <>
      <UserContextProvider>
        <ModulesContextProvider>
          <NotificationsContextProvider>
            <BreadcrumbContextProvider>
              <DrawerCollectionsContextProvider>
                {props.children}
              </DrawerCollectionsContextProvider>
            </BreadcrumbContextProvider>
          </NotificationsContextProvider>
        </ModulesContextProvider>
      </UserContextProvider>
    </>
  );
};

export default StateContextProvider;
