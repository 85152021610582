class UndoItem {
  constructor(perform, data) {
    this.perform = perform;
    this.data = data;
  }
}

class UndoStack {
  constructor() {
    this.stack = [];
    this.current = -1;
  }

  push(perform, data) {
    this.current++;
    this.stack.splice(this.current);
    this.stack.push(new UndoItem(perform, data));
  }

  undo() {
    let item;

    if (this.current >= 0) {
      item = this.stack[this.current];
      item.perform(false, item.data);
      this.current--;
    } else {
      // throw new Error("Already at oldest change");
      console.log("Already at oldest change");
    }
  }

  redo() {
    let item;

    item = this.stack[this.current + 1];
    if (item) {
      item.perform(true, item.data);
      this.current++;
    } else {
      // throw new Error("Already at newest change");
      console.log("Already at newest change");
    }
  }

  invalidateAll() {
    this.stack = [];
    this.current = -1;
  }
}

const undoStack = new UndoStack();

export default undoStack;
