import React, { useState, useRef, useEffect } from "react";
import useActivityTrack from "../../../../../hooks/useActivityTrack";
import { Link } from "react-router-dom";
import usePDF from "../../../../../hooks/usePDF";
import moment from "moment";
import "./my-design-card-styles.scss";

const MyDesignCard = ({
  title,
  modificationDate,
  myDesignId,
  productId,
  snap,
  config,
  onDelete,
  onEdit,
  isObsolete,
  productName,
  className,
}) => {
  const [editTitle, setEditTitle] = useState(false);
  const [deleteMyDesign, setDeleteMyDesign] = useState(false);
  const refInputTitle = useRef();
  const [data, error, fetching, { createPdf }] = usePDF();
  const [action, errorAction, fetchingAction, registerAction] =
    useActivityTrack();

  const handleOnClickEditTitle = () => {
    if (isObsolete) {
      return;
    }
    if (editTitle) {
      setEditTitle(false);
      return onEdit(refInputTitle.current.value);
    }
    setEditTitle(true);
  };

  const handleOnClickCreatePdf = () => {
    registerAction("DOWNLOADED_PDF", {
      downloadPdf: true,
      product: productId,
      myDesignId: myDesignId,
    });
    createPdf(productId, {
      customer: {
        city: "Barcelona",
        company: "Dimo Visual Creatives S.L.",
        customer_name: "Dani",
        date: "2020-01-10T17:35:39.526Z",
        edit_date: "2020-01-10T17:35:39.526Z",
        email: "dani@dimo.cat",
        id_customer: "string",
        phone: "646863201",
      },
      date: "2020-01-10T17:35:39.526Z",
      edit_date: "2020-01-10T17:35:39.526Z",
      id_project: "4d0e9e19-2d03-4ff2-8489-eb61680ce1ca",
      id_user: "string",
      model_id: productId,
      name_model: productName,
      price: 0,
      project_name: title,
      selected_options: config,
      show_price: false,
      snap: snap,
    });
  };

  useEffect(() => {
    if (editTitle) {
      refInputTitle.current.focus();
    }
  }, [editTitle]);

  return (
    <div
      className={`my-design-card-container ${className || ""} ${
        deleteMyDesign ? "bg-red text-white" : ""
      } ${isObsolete ? "my-design-card__obsolete" : ""}`}
    >
      <div className="snap-container">
        <img src={snap} alt="" className="img-fluid" />
      </div>
      <div className="info-container">
        {!deleteMyDesign && (
          <>
            {isObsolete && (
              <h6 className="obsolete-message text-white bg-red p-1">
                This product is no longer available
              </h6>
            )}
            <div className="title-my-design-container">
              <h3 className="text-grey-dark d-flex align-items-center flex-fill">
                <span className="text-red">Project name: </span>
                {!editTitle && (
                  <span className="ml-1" onDoubleClick={handleOnClickEditTitle}>
                    {title}
                  </span>
                )}
                {editTitle && (
                  <form onSubmit={handleOnClickEditTitle}>
                    <input
                      type="text"
                      ref={refInputTitle}
                      className="form-control input-edit-title with-border-down"
                      defaultValue={title}
                    />
                  </form>
                )}
              </h3>
              {!isObsolete && (
                <button
                  className="edit-title-btn"
                  onClick={handleOnClickEditTitle}
                >
                  <img src="imgs/edit_icon.svg" alt="" className="img-fluid" />
                </button>
              )}
            </div>
            <div className="product-name-and-date-container">
              <h4 className="product-name">{productName}</h4>
              <span className="date">
                {moment(modificationDate).format("DD-MM-YYYY")}
              </span>
            </div>
            {/* <div>{lastEdition}</div> */}
            <div className="group-action-btns">
              {!isObsolete && (
                <Link
                  to={`/configurator/${productId}/${myDesignId}`}
                  className="btn btn-corporate btn-filled"
                >
                  Edit
                </Link>
              )}
              <div>
                <button
                  onClick={setDeleteMyDesign.bind(this, true)}
                  className="action-btn"
                >
                  <img
                    src="imgs/trash_icon_grey.svg"
                    alt=""
                    className="icon icon-normal"
                  />
                  <img
                    src="imgs/trash_icon_red.svg"
                    alt=""
                    className="icon icon-hover"
                  />
                </button>
                {!isObsolete && (
                  <button
                    onClick={handleOnClickCreatePdf}
                    className="action-btn"
                  >
                    <img
                      src="imgs/paper_icon_grey.svg"
                      alt=""
                      className="icon icon-normal"
                    />
                    <img
                      src="imgs/paper_icon_red.svg"
                      alt=""
                      className="icon icon-hover"
                    />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
        {deleteMyDesign && (
          <div className="d-flex align-items-center justify-content-between">
            <span>¿Quieres eliminar definitivamente? </span>
            <div className="text-right">
              <button className="btn btn-white" onClick={onDelete}>
                Eliminar
              </button>
              <button
                className="btn btn-white"
                onClick={setDeleteMyDesign.bind(this, false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyDesignCard;
