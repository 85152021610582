import React, { useEffect, useState } from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.min.css";
import "./related-products-style.scss";
import Spinner from "../../../../blocks/Spinner";
import useCollections from "../../../../../hooks/useCollections";
import productConfigurator from "../../../../../services/ProductConfigurator";
import useModule from "../../../../../hooks/useModules";
import ProductCard from "../../../../blocks/ProductCard";
import "./related-products-style.scss";

const RelatedProduct = (props) => {
  const isRelatedProductsModuleActive = useModule(
    "CONFIGURATOR.RELATED_PRODUCTS"
  );
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [collections, error, fetching, getCollections] = useCollections();
  const [swiperCtrl, setSwiperCtrl] = useState(null);

  if (!isRelatedProductsModuleActive) {
    return null;
  }

  const getRelatedProducts = () => {
    let _relatedProducts = [];
    let trackIds = [];

    const relatedProductsIds =
      productConfigurator.configuration.model.related_models;

    Object.keys(collections).forEach((collectionKey) => {
      if (!collections[collectionKey].models) {
        return;
      }
      for (
        let i = 0, j = collections[collectionKey].models.length;
        i < j;
        i++
      ) {
        let relatedProductData = collections[collectionKey].models[i];
        for (let n = 0, m = relatedProductsIds.length; n < m; n++) {
          let relatedProductId = relatedProductsIds[n];
          if (relatedProductId === relatedProductData.code) {
            if (trackIds.indexOf(relatedProductId) < 0) {
              _relatedProducts.push({
                ...relatedProductData,
              });
            }
            trackIds.push(relatedProductId);
          }
        }
      }
    });
    setRelatedProducts(_relatedProducts);
  };

  const CustomNavigation = (props) => {
    const handleClickNavigation = (dir) => {
      switch (dir) {
        case "left":
          swiperCtrl.slidePrev();
          break;
        case "right":
        default:
          swiperCtrl.slideNext();
          break;
      }
    };

    if (!swiperCtrl) {
      return null;
    }

    if (props.numRelatedProducts <= swiperCtrl.params.slidesPerView) {
      return null;
    }

    return (
      <div>
        <button onClick={handleClickNavigation.bind(this, "left")}>
          {"<<"}
        </button>
        <button onClick={handleClickNavigation.bind(this, "right")}>
          {">>"}
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (relatedProducts.length <= 0 && props.lastConfigUpdate) {
      getCollections();
    }
    if (!props.lastConfigUpdate) {
      setRelatedProducts([]);
    }
  }, [props.lastConfigUpdate]);

  useEffect(() => {
    if (collections.length > 0) {
      getRelatedProducts();
    }
  }, [collections]);

  if (!props.lastConfigUpdate) {
    return null;
  }

  return (
    <div className="related-products-container">
      {fetching && <Spinner />}
      {!fetching && relatedProducts.length > 0 && (
        <>
          <Swiper
            slidesPerView={1}
            shouldSwiperUpdate={true}
            // navigation= {{
            // 	nextEl: '.swiper-button-next',
            // 	prevEl: '.swiper-button-prev'
            // }}
            breakpoints={{
              700: {
                slidesPerView: 5,
              },
            }}
            getSwiper={setSwiperCtrl}
          >
            {relatedProducts.map((relatedProduct, i) => {
              return (
                <ProductCard
                  key={relatedProduct.code}
                  to={`/configurator/${relatedProduct.code}`}
                  snapUrl={`${relatedProduct.snap}`}
                  productName={relatedProduct.name}
                />
              );
            })}
          </Swiper>
          <CustomNavigation numRelatedProducts={relatedProducts.length} />
        </>
      )}
      {!fetching && relatedProducts.length === 0 && (
        <div className="text-center">No related products</div>
      )}
    </div>
  );
};

export default RelatedProduct;
