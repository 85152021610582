import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Spinner from "../../blocks/Spinner";
import "./session-end-view-styles.scss";

const SessionEndView = () => {
  const params = useParams();
  const { redirectType } = params;
  const infoRedirect = useMemo(() => {
    switch (redirectType) {
      case "bad-credentials":
        return {
          title: "Please login to use the configurator",
          message: "Your are being redirect to the main site",
        };
      case "":
      default:
        return {
          title: "Your session expired",
          message: "Your are being redirect to the main site",
        };
    }
  }, [redirectType]);
  useEffect(() => {
    window.setTimeout(() => {
      window.location = "https://figueras.com";
    }, 2000);
  }, []);
  const { title, message } = infoRedirect;
  return (
    <div className="text-center session-end-view__container">
      <h1 className="text-red">{title}</h1>
      <p>{message}</p>
      <Spinner />
    </div>
  );
};

export default SessionEndView;
