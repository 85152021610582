import React, { useEffect, useContext, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import useCheckSession from "../../../hooks/useCheckSession";
import Spinner from "../../blocks/Spinner";
import "./login-view-styles.scss";

const LoginView = () => {
  const params = useParams();
  const { id: paramId, token: paramToken, productId } = params;
  const { checkSession, fetching, redirectExternalLogin } = useCheckSession();
  const { userState } = useContext(UserContext);
  const { token } = userState;
  useEffect(() => {
    if (token) {
      return;
    }
    checkSession(paramId, paramToken);
    return;
  }, [token, paramId, paramToken]);

  if (token) {
    if (productId) {
      return <Redirect to={`/configurator/${productId}`} />;
    }
    return <Redirect to="/" />;
  }

  if (redirectExternalLogin) {
    return (
      <Redirect
        to={`/session-end${paramId || paramToken ? "/bad-credentials" : ""}`}
      />
    );
  }

  return (
    <div className="container-login">
      <div>
        {redirectExternalLogin && (
          <div className="text-error">YOU CAN NOT BE HERE</div>
        )}
        {fetching && <Spinner />}
      </div>
    </div>
  );
};

export default LoginView;
