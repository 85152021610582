import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";

const ForceRedirect = (props) => {
  const { userState } = useContext(UserContext);

  if (
    (userState.token && props.ifUserLogged) ||
    (!userState.token && !props.ifUserLogged)
  ) {
    return <Redirect to={props.to} />;
  }
  return null;
};

export default ForceRedirect;
