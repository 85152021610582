import React, { createContext, useState } from "react";

const DrawerCollectionsContext = createContext();

const DrawerCollectionsContextProvider = (props) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <DrawerCollectionsContext.Provider value={{ isActive, setIsActive }}>
      {props.children}
    </DrawerCollectionsContext.Provider>
  );
};

export { DrawerCollectionsContext, DrawerCollectionsContextProvider };
