import { TextureLoader, CubeTextureLoader, RepeatWrapping } from "three";

class SceneMaterials {
  constructor() {
    this.textureLoader = new TextureLoader();
    this.cubeTextureLoader = new CubeTextureLoader();
  }

  setMaterial(material, matProps) {
    for (let prop in matProps) {
      switch (prop) {
        // SET COLOR
        case "color":
          material.color.set(matProps.color);
          break;

        case "map":
          const map = matProps.map;

          if (map && map !== null) {
            const urlMap = typeof map === "object" ? map.url : map;
            const mapSize = typeof map === "object" ? map.mapsize : undefined;
            const _offset = typeof map === "object" ? map.offset : undefined;

            let texture = this.textureLoader.load(urlMap);
            texture.wrapS = RepeatWrapping;
            texture.wrapT = RepeatWrapping;

            if (!mapSize) {
              texture.repeat.set(1, 1);
            } else {
              texture.repeat.set(mapSize.x, mapSize.y);
            }

            if (_offset) {
              /*
                            texture.offset.x = (typeof _offset.x === 'number')? _offset.x : 0;
                            texture.offset.y = (typeof _offset.y === 'number')? _offset.y : 0;
                            */
              texture.offset.set(
                typeof _offset.x === "number" ? _offset.x : 0,
                typeof _offset.y === "number" ? _offset.y : 0
              );
            }

            material.map = texture;
            material.needsUpdate = true;
            texture = null;
          } else {
            material.map = null;
            material.needsUpdate = true;
          }
          break;

        case "bumpMap":
          const bumpMap = matProps.bumpMap;

          if (bumpMap && bumpMap !== null) {
            const urlBumpMap =
              typeof bumpMap === "object" ? bumpMap.url : bumpMap;
            const bumpTexture = this.textureLoader.load(urlBumpMap);
            bumpTexture.wrapS = RepeatWrapping;
            bumpTexture.wrapT = RepeatWrapping;

            if (typeof bumpMap.mapsize === "object") {
              bumpTexture.repeat.set(bumpMap.mapsize.x, bumpMap.mapsize.y);
            } else {
              bumpTexture.repeat.set(1, 1);
            }

            material.bumpMap = bumpTexture;
            material.needsUpdate = true;
            material.bumpScale = bumpMap.bumpScale ? bumpMap.bumpScale : 1;
          } else {
            material.bumpMap = undefined;
            material.needsUpdate = true;
          }

          break;

        case "specular":
          const _specular = matProps.specular;
          material.specular.setRGB(_specular.r, _specular.g, _specular.b);
          break;

        case "shininess":
          material.shininess = matProps.shininess;
          break;

        case "emissive":
          material.emissive.set(matProps.emissive);
          break;

        case "emissiveMap":
          const emissiveMap = matProps.emissiveMap;
          if (emissiveMap && emissiveMap !== null) {
            const urlEmissiveMap =
              typeof emissiveMap === "object" ? emissiveMap.url : emissiveMap;
            const emissiveTexture = this.textureLoader.load(urlEmissiveMap);
            emissiveTexture.wrapS = RepeatWrapping;
            emissiveTexture.wrapT = RepeatWrapping;

            if (typeof emissiveMap.mapsize === "object") {
              emissiveTexture.repeat.set(
                emissiveMap.mapsize.x,
                emissiveMap.mapsize.y
              );
            } else {
              emissiveTexture.repeat.set(1, 1);
            }

            if (typeof emissiveMap.offset === "object") {
              emissiveTexture.offset.x =
                typeof emissiveMap.offset.x === "number"
                  ? emissiveMap.offset.x
                  : 0;
              emissiveTexture.offset.y =
                typeof emissiveMap.offset.y === "number"
                  ? emissiveMap.offset.y
                  : 0;
            }

            material.emissiveMap = emissiveTexture;
            material.needsUpdate = true;
          } else {
            material.emissiveMap = undefined;
            material.needsUpdate = true;
          }
          break;

        case "envMap":
          const envMap = matProps.envMap;
          if (envMap && envMap !== null) {
            const envTexture = this.cubeTextureLoader.load([
              envMap.cubeUrls[0],
              envMap.cubeUrls[1],
              envMap.cubeUrls[2],
              envMap.cubeUrls[3],
              envMap.cubeUrls[4],
              envMap.cubeUrls[5],
            ]);
            material.envMap = envTexture;

            material.needsUpdate = true;
          } else {
            material.envMap = undefined;
            material.needsUpdate = true;
          }
          break;

        default:
          break;
      }
    }
  }
}

export default SceneMaterials;
