import useHttp from "./useHttp";
import { EP } from "../services/EP";

const useContact = () => {
  const [data, error, fetching, { post }] = useHttp(null);
  const performContact = (contactData) => {
    post(`${EP}contact`, contactData);
  };
  return { performContact, data, error, fetching };
};

export default useContact;
