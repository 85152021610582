import React, { useEffect } from "react";
// import productConfigurator from "../../../services/ProductConfigurator";

const ArView = () => {
  useEffect(() => {
    //productConfigurator.sceneRenderer.arModule.init();
  }, []);

  return <div>Ar VIEW</div>;
};

export default ArView;
