import React from "react";
import { useHistory } from "react-router-dom";
import useNotifications from "../../../hooks/useNotifications";
import useModules from "../../../hooks/useModules";
import "./search-form.scss";

const Searchform = () => {
  const [setNotification] = useNotifications();
  const searchModuleActive = useModules("SEARCH_PRODUCT");
  const history = useHistory();

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const searchTerm = e.target.querySelector("input").value.trim();
    if (searchTerm) {
      history.push(`/search-result/${searchTerm}`);
    } else {
      setNotification({
        title: "",
        text: "Please write down some text",
        type: "warning",
      });
    }
    e.target.querySelector("input").value = "";
  };

  if (!searchModuleActive) {
    return null;
  }

  return (
    <div className="search-form-container">
      {(() => {
        return (
          <form action="" onSubmit={handleOnSubmit} className="form-inline">
            <input
              type="text"
              className="form-control with-border-down search-input"
              placeholder="Write a keyword"
            />
            <button
              type="submit"
              className="btn btn-corporate btn-border-thin btn-with-icon mx-2"
            >
              <img
                src="imgs/search_icon_red.svg"
                className="icon normal-state"
                alt=""
              />
              <img
                src="imgs/search_icon_white.svg"
                className="icon hover-state"
                alt=""
              />
              <span className="label">Search</span>
            </button>
          </form>
        );
      })()}
    </div>
  );
};

export default Searchform;
