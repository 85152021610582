import React from "react";
import productConfigurator from "../../../../../services/ProductConfigurator";
import SavePdfBtn from "../SavePdfBtn";
import SaveMyDesignBtn from "../SaveMyDesignBtn";

const ConfiguratorActionsMenu = ({ myDesign, getMyDesigns }) => {
  if (!productConfigurator.configuration) {
    return null;
  }

  return (
    <div>
      <SaveMyDesignBtn myDesign={myDesign} getMyDesigns={getMyDesigns} />
      <SavePdfBtn myDesignId={myDesign ? myDesign.mydesignId : undefined} />
    </div>
  );
};

export default ConfiguratorActionsMenu;
