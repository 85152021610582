import { useContext } from "react";
import { ModulesContext } from "../context/ModulesContext";

const useModules = (_module) => {
  const checkModules = useContext(ModulesContext);
  if (_module) {
    return checkModules(_module);
  }
  return checkModules;
};

export default useModules;
