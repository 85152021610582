import useHttp from "./useHttp";
import { EP } from "../services/EP";

const usePDF = (initialValue = []) => {
  const [data, error, fetching, { post }] = useHttp(initialValue);

  const createPdf = async (fileName = "export", offerData) => {
    if (fetching) {
      return null;
    }
    try {
      const pdfData = await post(`${EP}offer/export-base64`, offerData);
      const a = document.createElement("a");
      const pdfAsDataUri = "data:application/pdf;base64," + pdfData;
      a.download = `${fileName}-${Date.now()}.pdf`;
      a.type = "application/pdf";
      a.href = pdfAsDataUri;
      a.click();
    } catch (err) {
      console.log(err);
    }
  };

  return [data, error, fetching, { createPdf }];
};

export default usePDF;
