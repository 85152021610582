import React from "react";
import { Link } from "react-router-dom";
import "./product-card-styles.scss";

const ProductCard = ({ to, snapUrl, productName }) => {
  return (
    <Link to={to} className="product-card">
      <img src={snapUrl} alt="" />
      <span className="hover-info-product">
        <h1>{productName}</h1>
      </span>
    </Link>
  );
};

export default ProductCard;
