import React, { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import Routing from "./Routing";
import StateContextProvider from "../context/StateContext";
import MainTemplate from "./templates/MainTemplate";

const App = () => {
  useEffect(() => {
    console.log("Configurator developed by:");
    console.log(
      "%cDimo Visual Creatives",
      "background: #222; color: #2ed5fa; font-size: 40px"
    );
    console.log(
      "%chttps://dimo.cat",
      "background: #222; color: #000; font-size: 20px"
    );
  }, []);
  return (
    <StateContextProvider>
      <Router basename="/">
        <MainTemplate>
          <Routing />
        </MainTemplate>
      </Router>
    </StateContextProvider>
  );
};

export default App;
