import { useState } from "react";
import useHttp from "./useHttp";
import { EP } from "../services/EP";

const useCollections = (initalValue = []) => {
  const [collections, setCollections] = useState(initalValue);
  const [data, error, fetching, { get }] = useHttp(initalValue);

  const validCollections = [
    "fixed-seating",
    "cinema",
    "stadiums-and-arenas",
    "waiting-areas",
  ];

  const fetchCollections = (collectionId) => {
    get(`${EP}data/collections`, (data) => {
      if (collectionId) {
        setCollections(data[collectionId]);
      } else {
        setCollections(
          Object.keys(data)
            .filter((collectionId) => {
              return validCollections.includes(collectionId);
            })
            .sort((a, b) => {
              return validCollections.indexOf(a) - validCollections.indexOf(b);
            })
            .map((k) => {
              return {
                ...data[k],
                id: k,
              };
            })
        );
      }
    });
  };

  return [collections, error, fetching, fetchCollections];
};

export default useCollections;
