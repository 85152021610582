import React from "react";
import Menu from "../Menu/";
import { Link, useRouteMatch } from "react-router-dom";
import "./top-bar-styles.scss";

const TopBar = () => {
  const isSessionEndView = useRouteMatch("/session-end");
  if (isSessionEndView) {
    return null;
  }
  return (
    <div className="topbar-container">
      <div>
        <Link to="/">
          <img
            src="imgs/logo_figueras_group_black.svg"
            className="logo"
            alt=""
          />
        </Link>
      </div>
      <Menu />
    </div>
  );
};

export default TopBar;
