import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import localMaterials from "../../../data/materials/materials.json";
import Spinner from "../../blocks/Spinner";
import ConfiguratorMenu from "./ConfiguratorViewParts/ConfiguratorMenu";
import ConfiguratorActionsMenu from "./ConfiguratorViewParts/ConfiguratorActionsMenu";
import RenderActionsMenu from "./ConfiguratorViewParts/RenderActionsMenu";
import ProductInfo from "./ConfiguratorViewParts/ProductInfo";
import MoreInfo from "./ConfiguratorViewParts/MoreInfo";
import BgVideo from "./ConfiguratorViewParts/BgVideo";
import productConfigurator from "../../../services/ProductConfigurator";
import undoStack from "../../../services/UndoStack";
import useProduct from "../../../hooks/useProduct";
import useNotifications from "../../../hooks/useNotifications";
import useBreadcrumb from "../../../hooks/useBreadcrumb";
import useMyDesigns from "../../../hooks/useMyDesigns";
import useModules from "../../../hooks/useModules";
import useActivityTrack from "../../../hooks/useActivityTrack";
import "./configurator-view-styles.scss";

const ConfiguratorView = (props) => {
  const [lastConfigUpdate, setLastConfigUpdate] = useState(null);
  // const [percent, setPercent] = useState(0);
  const history = useHistory();
  const [isArMode, setIsArMode] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [product, error, fetching, getProduct] = useProduct();
  const [setNotification] = useNotifications();
  const [breadcrumbData, setBreadcrumbData] = useBreadcrumb();
  const [myDesign, errorMyDesign, fetchingMyDesign, { getMyDesigns }] =
    useMyDesigns(null);

  const [action, errorActivity, fetchingAction, registerAction] =
    useActivityTrack();

  const arModuleActive = useModules("CONFIGURATOR.AR_MODE");
  const configurationMenuModuleActive = useModules(
    "CONFIGURATOR.CONFIGURATION_MENU"
  );
  const handleResize = (e) => {
    productConfigurator.updateCanvasSize();
  };

  const onGetProduct = (product) => {
    // console.log('A new product is about to be get!');
    window.addEventListener("resize", handleResize);
    productConfigurator.init();
    productConfigurator.loadConfiguration(
      product,
      localMaterials,
      (progress) => {
        // console.log(progress);
        // setPercent(
        //   Math.floor((progress.loaded / Number(progress.total)) * 100)
        // );
      },
      (configMenu, configOptions, model) => {
        productConfigurator.updateCanvasSize();
        (() => {
          window.setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 800);
        })();
        setBreadcrumbData({
          levels: [
            ...breadcrumbData.levels.slice(0, 1),
            {
              name: productConfigurator.configuration.model.name,
              link: `/configurator/${props.match.params.product}`,
            },
          ],
          isLoading: false,
        });
        if (props.match.params.designId) {
          getMyDesigns(props.match.params.designId);
        } else {
          updateLastConfiguration();
        }
      }
    );
  };

  // const retriveMyDesign = myDesignId => {
  //   getMyDesignsLocalApi(
  //     myDesignId,
  //     `
  //       name
  //       finishings{
  //         configurationKey
  //         configurationValue
  //       }
  //     `
  //   );
  // };

  const handleOnChangeConfiguration = (optionName, optionValue) => {
    const oldConfig = {
      option_name: optionName,
      option_value: productConfigurator.getCurrentConfiguration()[optionName],
    };
    const newConfig = {
      option_name: optionName,
      option_value: optionValue,
    };
    productConfigurator.changeConfigurationByOption(
      optionName,
      optionValue,
      false,
      (optionSelected, restrictions) => {
        for (let i = 0, j = restrictions.length; i < j; i++) {
          setNotification({
            title: "",
            text: restrictions[i],
            type: "warning",
          });
        }
        updateLastConfiguration();
        registerAction("SET_FINNISHING", {
          product: props.match.params.product,
          configurationKey: optionName,
          configurationValue: optionValue,
        });
      }
    );
    undoStack.push(
      (redo, data) => {
        productConfigurator.changeConfigurationByOption(
          data[redo ? 1 : 0]["option_name"],
          data[redo ? 1 : 0]["option_value"],
          false,
          () => {
            updateLastConfiguration();
          }
        );
      },
      [oldConfig, newConfig]
    );
  };

  const invalidateAll = () => {
    console.log("invalidate All!!");
    updateLastConfiguration(null);
    window.removeEventListener("resize", handleResize);
    undoStack.invalidateAll();
    productConfigurator.destroyConfigurator(props.match.params.product);
  };

  const updateLastConfiguration = (time = Date.now()) => {
    setLastConfigUpdate(time);
  };

  const handleToggleArMode = () => {
    if (!isArMode) {
      productConfigurator.sceneRenderer.enableArMode();
      return setIsArMode(true);
    }
    productConfigurator.sceneRenderer.disableArMode();
    return setIsArMode(false);
  };

  const handleToggleFullScreen = () => {
    setIsFullScreen((prevFullScreen) => {
      return !prevFullScreen;
    });
    window.setTimeout(() => {
      productConfigurator.updateCanvasSize();
    }, 100);
  };

  useEffect(() => {
    getProduct(props.match.params.product, onGetProduct);
    return invalidateAll;
  }, [props.match.params.product]);

  useEffect(() => {
    if (myDesign) {
      productConfigurator.launchConfiguration(myDesign.config);
      updateLastConfiguration();
      // setBreadcrumbData({
      //   levels: [
      //     ...breadcrumbData.levels.slice(0, 2),
      //     {
      //       name: myDesign.title,
      //       link: `/configurator/${props.match.params.product}/${props.match.params.designId}`
      //     }
      //   ],
      //   isLoading: false
      // });
    }
  }, [myDesign]);

  if (error) {
    setNotification({
      title: "Invalid product",
      text: "The product requested does not exists",
      type: "error",
    });
    history.push("/");
  }

  return (
    <div className="configurator-view-container">
      <div className="row-top">
        <div
          className={`loading-product ${
            !lastConfigUpdate ? "loading" : "done"
          }`}
        >
          <Spinner />
        </div>
        <div className="col-2 col-left">
          <div
            id="renderer-cont"
            className={`${isArMode ? "ar-mode" : ""} ${
              isFullScreen ? "full-screen" : ""
            }`}
          >
            <RenderActionsMenu
              onToggleArMode={handleToggleArMode}
              onToggleFullScreen={handleToggleFullScreen}
            />
          </div>
          <BgVideo
            show={isArMode && arModuleActive}
            className={`${isFullScreen ? "full-screen" : ""}`}
          />
        </div>
        <div className="col-2 col-right">
          <ProductInfo
            lastConfigUpdate={lastConfigUpdate}
            myDesign={myDesign}
          />
          {configurationMenuModuleActive && (
            <ConfiguratorMenu
              lastConfigUpdate={lastConfigUpdate}
              onChangeConfiguration={handleOnChangeConfiguration}
            />
          )}
          <ConfiguratorActionsMenu
            myDesign={myDesign}
            getMyDesigns={getMyDesigns}
            // onUpdateMyDesign={retriveMyDesign}
          />
        </div>
      </div>
      <div className="row-bottom">
        <MoreInfo lastConfigUpdate={lastConfigUpdate} />
      </div>
    </div>
  );
};

export default ConfiguratorView;
